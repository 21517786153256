import { styled } from "@mui/system";

export const MaterialStyle = styled("div")(({ theme }) => ({
	// height: "500px",
	// width:"100%",
	"& .MuiTableHead-root": {
		background: "#ededed",
		boxShadow: theme.shadows[3],
		zIndex: 1,
	},
	"& tbody tr:nth-of-type(odd)": {
		background: "#fcfcfc",
	},
	"& tbody > .MuiTableRow-root:hover": {
		background: "#f7f7f7",
	},
	//disable input number arrows => Chrome, Safari, Edge, Opera
	"& input::-webkit-inner-spin-button": {
		WebkitAppearance: "none",
		margin: 0,
	},
	//disable input number  => Firefox
	"& input[type=number]": {
		MozAppearance: "textfield",
	},
}));

export const TotalCostStyle = {
	paddingRight: "30px",
	"& >:first-of-type": {
		paddingRight: "10px",
	},
	"& >:last-child": {
		borderBottom: "1px solid black",
	},
};
