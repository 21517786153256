import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Avatar, Button, CircularProgress, Grid, ListItemText, ListItemAvatar, ListItemIcon, MenuItem, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";

import Layout from "../../layout/layout.js";
import { getProjectSettings, getBySystemPipeVendor, getSortedBestandteile, addProjectSettings, editProjectSettings, getJsonData } from "./projectSettingsController.js";
import { getBuildingTypes, getValidSystemGroups, selectInput } from "../../store/gloablFunctions.js";
import { _t } from "../../store/translate.js";
import { GridPadding } from "../../mainStyle.js";
import Images from "../../store/images.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import { ProjectSettingsStyles } from "./projectSettingsStyle.js";

function ProjectSettings(props) {
	const logo = Images(props.vendor.postfix)?.logo;

	const navigate = useNavigate();
	const { id: projectId } = useParams();

	const isNewProject = projectId === "addNewProject";

	var jsonData = React.useRef(null);
	var parsedJson = React.useRef(null);

	/* Projectname */
	const [projectname, setProjectname] = React.useState(_t("Neues Projekt"));
	const handleChange_projectname = (value) => {
		setProjectname(value);
	};

	/* Gebaeudeklasse + Heizlast */
	const [gebaeudeklasse, setGebaeudeklasse] = React.useState({});
	const gebaeudeklassen = getBuildingTypes(props.vendor.postfix);

	const handleChange_gebaeudeklasse = (event) => {
		setGebaeudeklasse(gebaeudeklassen.find((element) => element.name === event.target.value));
	};
	const handleChange_heizlast = (event) => {
		setGebaeudeklasse({
			name: _t("Benutzerdefiniert"),
			power_per_m2: event.target.valueAsNumber,
			edit: true,
		});
	};
	const setGebaeudeklasse_byInput = (power_per_m2) => {
		var found = gebaeudeklassen.find((element) => element.power_per_m2 === power_per_m2);
		if (found) {
			setGebaeudeklasse(found);
		} else {
			setGebaeudeklasse({
				name: _t("Benutzerdefiniert"),
				power_per_m2: power_per_m2,
				edit: true,
			});
		}
	};

	/* Anlagentyp + Vorlauftemperatur, Spreizung min, Spreizung max */
	const [anlagentyp, setAnlagentyp] = React.useState({});
	const anlagentypen = [
		{ name: _t("Wärmepumpe"), input_temperature: 35, spreading_min: 5, spreading_max: 7 },
		{ name: _t("Brennwert- / Niedertemperaturkessel"), input_temperature: 45, spreading_min: 5, spreading_max: 15 },
		{ name: _t("Benutzerdefiniert"), input_temperature: 45, spreading_min: 5, spreading_max: 15, edit: true },
	];
	const handleChange_anlagentyp = (name) => {
		setAnlagentyp(anlagentypen.find((element) => element.name === name));
	};
	const handleChange_inputTemperature = (event) => {
		setAnlagentyp({
			name: anlagentyp.name,
			input_temperature: event.target.valueAsNumber,
			spreading_min: anlagentyp.spreading_min,
			spreading_max: anlagentyp.spreading_max,
			edit: anlagentyp.edit,
		});
	};
	const handleChange_spreadingMin = (event) => {
		setAnlagentyp({
			name: anlagentyp.name,
			input_temperature: anlagentyp.input_temperature,
			spreading_min: event.target.valueAsNumber,
			spreading_max: anlagentyp.spreading_max,
			edit: anlagentyp.edit,
		});
	};
	const handleChange_spreadingMax = (event) => {
		setAnlagentyp({
			name: anlagentyp.name,
			input_temperature: anlagentyp.input_temperature,
			spreading_min: anlagentyp.spreading_min,
			spreading_max: event.target.valueAsNumber,
			edit: anlagentyp.edit,
		});
	};
	const setAnlagentyp_byInput = (input_temperature, spreading_min, spreading_max) => {
		var found = anlagentypen.find(
			(element) => element.input_temperature === input_temperature && element.spreading_min === spreading_min && element.spreading_max === spreading_max
		);
		if (found) {
			setAnlagentyp(found);
		} else {
			setAnlagentyp({ name: _t("Benutzerdefiniert"), input_temperature: input_temperature, spreading_min: spreading_min, spreading_max: spreading_max, edit: true });
		}
	};

	/* FBHSystemGruppen */
	var FBHSystemGruppen = React.useRef(null);

	/* Systemtyp + System */
	const getFirstSystemtyp = () => {
		try {
			if (FBHSystemGruppen) {
				for (const [firstSystemtypKey, firstSystemtyp] of Object.entries(FBHSystemGruppen.current)) {
					if (firstSystemtyp.SystemSets) {
						var firstSystemSets = Object.keys(firstSystemtyp.SystemSets);
						if (Object.keys(firstSystemSets).length > 0) {
							var firstSystemKey = null;
							var firstSystem = {};
							if (firstSystemSets.length > 0) {
								firstSystemKey = firstSystemSets[0];
								firstSystem = firstSystemtyp.SystemSets[firstSystemKey];
							}
							return {
								name: firstSystemtyp.display,
								key: firstSystemtypKey,
								systemSets: firstSystemtyp.SystemSets,
								systemName: firstSystem.display,
								systemKey: firstSystemKey,
							};
						}
					}
				}
			} else {
				return {};
			}
		} catch (e) {
			console.error(e);
			throw new Error("Bitte wenden Sie sich an den Administrator, um die JSON-Datendatei zu überprüfen.");
		}
	};
	const [systemtyp, setSystemtyp] = React.useState({});
	const handleChange_systemtyp = async (event) => {
		var newKey = event.target.value;
		var newFBHSystemtyp = FBHSystemGruppen.current[newKey];
		var newSystemSets = Object.keys(newFBHSystemtyp.SystemSets);
		var newSystemKey = null;
		var newSystem = {};
		if (newSystemSets.length > 0) {
			newSystemKey = Object.keys(newFBHSystemtyp.SystemSets)[0];
			newSystem = newFBHSystemtyp.SystemSets[newSystemKey];
		}
		var newSystemtyp = {
			name: newFBHSystemtyp.display,
			key: event.target.value,
			systemSets: newFBHSystemtyp.SystemSets,
			systemName: newSystem.display,
			systemKey: newSystemKey,
		};
		setSystemtyp(newSystemtyp);

		await setSystemtypSubdependencies(newSystemtyp);
	};
	const handleChange_system = (event) => {
		var newSystemKey = event.target.value;
		var newFBHSystem = systemtyp.systemSets[newSystemKey];
		var newSystemtyp = {
			name: systemtyp.name,
			key: systemtyp.key,
			systemSets: systemtyp.systemSets,
			systemName: newFBHSystem.display,
			systemKey: newSystemKey,
		};
		setSystemtyp(newSystemtyp);
		setSystemSubDependencies(newSystemtyp);
	};
	const getSystemtyp_byInput = (systemtypKey, systemKey, newFBHSystemGruppen) => {
		var newFBHSystemtyp = newFBHSystemGruppen[systemtypKey];
		var newFBHSystem = newFBHSystemtyp.SystemSets[systemKey];
		return {
			name: newFBHSystemtyp.display,
			key: systemtypKey,
			systemSets: newFBHSystemtyp.SystemSets,
			systemName: newFBHSystem.display,
			systemKey: systemKey,
		};
	};

	/* Mindestens ein Verteiler pro... */
	const distributionTypes = [
		{ name: _t("Geschoss"), value: "floor", distribution_per_flat: false, distribution_per_floor: true },
		{ name: _t("Wohnung"), value: "flat", distribution_per_flat: true, distribution_per_floor: false },
		{ name: _t("Projekt"), value: "project", distribution_per_flat: false, distribution_per_floor: false },
	];
	const [distributionType, setDistributionType] = React.useState({});
	const handleChange_distributionType = (event) => {
		setDistributionType(distributionTypes.find((element) => element.value === event.target.value));
	};
	const setDistributionType_byInput = (distribution_per_flat, distribution_per_floor) => {
		var found = distributionTypes.find((element) => element.distribution_per_flat === distribution_per_flat && element.distribution_per_floor === distribution_per_floor);
		setDistributionType(found);
	};

	/* Verteiler */
	const getDistributors = (newSystemtypKey, newFBHSystemGruppen) => {
		return newFBHSystemGruppen[newSystemtypKey]["VerteilerSchrankSets"];
	};
	const [distributors, setDistributors] = React.useState({});
	const getFirstDistributor = (newDistributors) => {
		var firstDistributor = {};
		Object.entries(newDistributors).forEach((entry) => {
			const [key, obj] = entry;
			if (obj.isDefault) {
				firstDistributor = { key: key, name: obj.display, bestandteile: obj.bestandteile };
			}
		});
		return firstDistributor;
	};
	const [distributor, setDistributor] = React.useState({});
	const handleChange_distributor = (event) => {
		var key = event.target.value;
		var newDistributor = distributors[key];
		setDistributor({ key: key, name: newDistributor.display, bestandteile: newDistributor.bestandteile });
		setDistributorSubGroups(getSortedBestandteile(parsedJson.current, newDistributor.bestandteile, true));
	};
	const getDistributor_byInput = (newDistributors, distributorKey) => {
		return { key: distributorKey, name: newDistributors[distributorKey].display, bestandteile: newDistributors[distributorKey].bestandteile };
	};
	/* Verteiler Subgroups*/
	const [distributorSubGroups, setDistributorSubGroups] = React.useState({});
	const handleChange_distributorSubGroup = (event, item) => {
		var newDistributors = {};
		Object.entries(distributorSubGroups).forEach((entry) => {
			const [key, obj] = entry;
			newDistributors[key] = obj;
		});
		newDistributors[item]["selected"] = event.target.value;
		setDistributorSubGroups(newDistributors);
	};
	const getDistributorSubGroupsForSaving = () => {
		var result = {};
		Object.entries(distributorSubGroups).forEach((entry) => {
			const [key, obj] = entry;
			result[key] = obj["selected"];
		});
		return result;
	};
	const setDistributorSubGroups_byInput = (bestandteile, distributor_sub_groups) => {
		if (distributor_sub_groups) {
			var newDistributorSubGroups = getSortedBestandteile(parsedJson.current, bestandteile, true);
			Object.entries(distributor_sub_groups).forEach((entry) => {
				const [key, obj] = entry;
				if (newDistributorSubGroups[key] && newDistributorSubGroups[key]["selected"]) {
					newDistributorSubGroups[key]["selected"] = obj;
				}
			});
			setDistributorSubGroups(newDistributorSubGroups);
		}
	};

	/* Regelung */
	const getControlSystems = (newSystemtypKey, newFBHSystemGruppen) => {
		return newFBHSystemGruppen[newSystemtypKey]["Einzelraumregelung"];
	};
	const [controlSystems, setControlSystems] = React.useState({});
	const getFirstControlSystem = (newControlSystems) => {
		var firstControlSystem = {};
		Object.entries(newControlSystems).forEach((entry) => {
			const [key, obj] = entry;
			if (obj.isDefault) {
				firstControlSystem = { key: key, name: obj.display, bestandteile: obj.bestandteile };
			}
		});
		return firstControlSystem;
	};
	const [controlSystem, setControlSystem] = React.useState({});
	const handleChange_controlSystem = (event) => {
		var key = event.target.value;
		var newControlSystem = controlSystems[key];
		setControlSystem({ key: key, name: newControlSystem.display, bestandteile: newControlSystem.bestandteile });
		setControlSystemSubGroups(getSortedBestandteile(parsedJson.current, newControlSystem.bestandteile, false));
	};
	const getControlSystem_byInput = (newControlSystems, controlSystemKey) => {
		return { key: controlSystemKey, name: newControlSystems[controlSystemKey].display, bestandteile: newControlSystems[controlSystemKey].bestandteile };
	};
	/* Regelung Subgroups*/
	const [controlSystemSubGroups, setControlSystemSubGroups] = React.useState({});
	const handleChange_controlSystemSubGroup = (event, item) => {
		var newControlSystems = {};
		Object.entries(controlSystemSubGroups).forEach((entry) => {
			const [key, obj] = entry;
			newControlSystems[key] = obj;
		});
		newControlSystems[item]["selected"] = event.target.value;
		setControlSystemSubGroups(newControlSystems);
	};
	const getControlSystemSubGroupsForSaving = () => {
		var result = {};
		Object.entries(controlSystemSubGroups).forEach((entry) => {
			const [key, obj] = entry;
			result[key] = obj["selected"];
		});
		return result;
	};
	const setControlSystemSubGroups_byInput = (bestandteile, control_system_sub_groups) => {
		if (control_system_sub_groups) {
			var newControlSystemSubGroups = getSortedBestandteile(parsedJson.current, bestandteile, false);
			Object.entries(control_system_sub_groups).forEach((entry) => {
				const [key, obj] = entry;
				if (newControlSystemSubGroups[key] && newControlSystemSubGroups[key]["selected"]) {
					newControlSystemSubGroups[key]["selected"] = obj;
				}
			});
			setControlSystemSubGroups(newControlSystemSubGroups);
		}
	};

	/* Rohrtyp  mit max. Kreislänge und  max. Druckverlust*/
	const getPipes = (newSystemtyp, newFBHSystemGruppen) => {
		var pipesArray = newSystemtyp.systemSets[newSystemtyp.systemKey]["schichten"]["ROHR"];
		var newPipes = {};
		pipesArray.forEach((data) => {
			var pipeKey = data["multiPurposeHandle"];
			newPipes[pipeKey] = newFBHSystemGruppen[newSystemtyp.key]["Rohrdaten"][pipeKey];
		});
		return newPipes;
	};
	const [pipes, setPipes] = React.useState({});
	const getFirstPipe = (newPipes, newSystemtyp) => {
		var firstPipeKey = Object.keys(newPipes)[0];
		var firstPipe = FBHSystemGruppen.current[newSystemtyp.key]["Rohrdaten"][firstPipeKey];
		return {
			key: firstPipeKey,
			name: firstPipe["display"],
			maxMax_circuit_length: firstPipe["maxMaxKreislaenge"],
			max_circuit_length: firstPipe["defaultMaxKreislaenge"],
			maxMax_pressure_loss: firstPipe["maxMaxDruckverlust"],
			max_pressure_loss: firstPipe["defaultMaxDruckverlust"],
		};
	};
	const [pipe, setPipe] = React.useState({});
	const handleChange_Pipe = (event) => {
		var key = event.target.value;
		var newPipe = pipes[key];
		setPipe({
			key: key,
			name: newPipe["display"],
			maxMax_circuit_length: newPipe["maxMaxKreislaenge"],
			max_circuit_length: newPipe["defaultMaxKreislaenge"],
			maxMax_pressure_loss: newPipe["maxMaxDruckverlust"],
			max_pressure_loss: newPipe["defaultMaxDruckverlust"],
		});
	};
	const handleChange_circuitLength = (event) => {
		setPipe({
			key: pipe.key,
			name: pipe["display"],
			maxMax_circuit_length: pipe.maxMax_circuit_length,
			max_circuit_length: event.target.value,
			maxMax_pressure_loss: pipe.maxMax_pressure_loss,
			max_pressure_loss: pipe.max_pressure_loss,
		});
	};
	const handleChange_pressureLoss = (event) => {
		setPipe({
			key: pipe.key,
			name: pipe["display"],
			maxMax_circuit_length: pipe.maxMax_circuit_length,
			max_circuit_length: pipe.max_circuit_length,
			maxMax_pressure_loss: pipe.maxMax_pressure_loss,
			max_pressure_loss: event.target.value,
		});
	};
	const getPipe_byInput = (newPipes, pipeKey, max_circuit_length, max_pressure_loss) => {
		var newPipe = newPipes[pipeKey];
		return {
			key: pipeKey,
			name: newPipe["display"],
			maxMax_circuit_length: newPipe["maxMaxKreislaenge"],
			max_circuit_length: max_circuit_length,
			maxMax_pressure_loss: newPipe["maxMaxDruckverlust"],
			max_pressure_loss: max_pressure_loss,
		};
	};
	/* Rohrabstände */
	const getPipeDistances = async (newSystemtyp, newPipe, language) => {
		var dataToSubmit = {
			system_group: newSystemtyp.key,
			system_name: newSystemtyp.systemKey,
			pipe: newPipe.key,
			vendor: props.vendor._id,
			json_data: jsonData.current._id,
			language: language,
		};
		return await getBySystemPipeVendor(dataToSubmit);
	};
	const [pipeDistances, setPipeDistances] = React.useState([]);
	const getPipeDistance = (newPipeDistances, index) => {
		return { value: newPipeDistances[index].margin, index: index };
	};
	const [pipeDistanceMin, setPipeDistanceMin] = React.useState({});
	const [pipeDistanceMax, setPipeDistanceMax] = React.useState({});
	const handleChange_pipeDistanceMin = (event) => {
		var pipeDistanceMin = getPipeDistance(pipeDistances, event.target.value);
		setPipeDistanceMin(pipeDistanceMin);
	};
	const handleChange_pipeDistanceMax = (event) => {
		var pipeDistanceMax = getPipeDistance(pipeDistances, event.target.value);
		setPipeDistanceMax(pipeDistanceMax);
	};
	/* Randzonen */
	const [separate_border_zones, setSeparate_border_zones] = React.useState(true);

	/* Verlegemuster */
	const layingPatterns = [
		{ name: "Schnecke", value: "S" },
		{ name: "Mäander", value: "M" },
		{ name: "Doppelmäander", value: "DM" },
	];
	const [layingPattern, setLayingPattern] = React.useState({});
	const handleChange_layingPattern = (value) => {
		var newLayingPattern = layingPatterns.find((l) => l.value === value);
		setLayingPattern(newLayingPattern);
	};

	/* Estrich */
	const getScreedArray = (newSystemtyp) => {
		var newScreedArray = newSystemtyp.systemSets[newSystemtyp.systemKey]["schichten"]["ESTRICH"];
		if (newScreedArray) {
			newScreedArray = newScreedArray.filter((s) => {
				return s["multiPurposeHandle"];
			});
		}
		return newScreedArray?.length > 0 ? newScreedArray : null;
	};
	const [screedArray, setScreedArray] = React.useState([]);
	const getFirstScreed = (newScreedArray) => {
		return newScreedArray ? newScreedArray[0] : {};
	};
	const [screed, setScreed] = React.useState({});
	const handleChangeScreed = (event) => {
		var newScreed = screedArray.find((s) => s.display === event.target.value);
		setScreed(newScreed);
		var newScreedAdditives = getScreedAdditives(systemtyp, newScreed, FBHSystemGruppen.current);
		setScreedAdditives(newScreedAdditives);
		if (newScreedAdditives) {
			var newScreedAdditive = getFirstScreedAdditive(newScreedAdditives);
			setScreedAdditive(newScreedAdditive);
		}
	};
	const getScreed_byInput = (newScreedArray, screedKey) => {
		return newScreedArray ? newScreedArray.find((s) => s["multiPurposeHandle"] === screedKey || (s["multiPurposeHandle"] === false && screedKey === "")) : null;
	};
	/* Estrich-Zusatzmittel */
	const getScreedAdditives = (newSystemtyp, newScreed, newFBHSystemGruppen) => {
		if (newScreed) {
			var newScreedAdditives = newFBHSystemGruppen[newSystemtyp.key][newScreed["multiPurposeHandle"]];
			return newScreedAdditives && Object.keys(newScreedAdditives).length > 0 ? newScreedAdditives : null;
		}
		return null;
	};
	const [screedAdditives, setScreedAdditives] = React.useState({});
	const getFirstScreedAdditive = (newScreedAdditives) => {
		if (newScreedAdditives) {
			var firstScreedAdditiveKey = Object.keys(newScreedAdditives)[0];
			return { key: firstScreedAdditiveKey, name: newScreedAdditives[firstScreedAdditiveKey]["display"] };
		}
		return {};
	};
	const [screedAdditive, setScreedAdditive] = React.useState({});
	const handleChange_screedAdditive = (event) => {
		var newKey = event.target.value;
		var newName = screedAdditives[newKey]["display"];
		setScreedAdditive({ key: newKey, name: newName });
	};
	const getScrredAdditve_byInput = (newScreedAdditives, screedAdditiveKey) => {
		if (newScreedAdditives) {
			return { key: screedAdditiveKey, name: newScreedAdditives[screedAdditiveKey]["display"] };
		}
		return {};
	};

	/* usersProjectData */
	const [project_address, setProject_address] = React.useState({});
	const [delivery_address, setDelivery_address] = React.useState({});
	const [receiver_address, setReceiver_address] = React.useState({});
	const [detail, setDetail] = React.useState("");
	const labelForAdressItem = {
		company: _t("Firma"),
		name: _t("Name"),
		street: _t("Straße"),
		zip: _t("PLZ"),
		city: _t("Ort"),
		phone: _t("Telefon"),
		email: _t("E-Mail"),
		country: _t("Land"),
		note: _t("Bemerkung"),
	};
	const handleChange_usersProjectData = (adress, item, value) => {
		if (adress === "project_address") {
			project_address[item] = value;
			setProject_address({
				company: project_address.company,
				name: project_address.name,
				street: project_address.street,
				zip: project_address.zip,
				city: project_address.city,
				country: project_address.country,
				phone: project_address.phone,
				email: project_address.email,
				note: project_address.note,
			});
		} else if (adress === "delivery_address") {
			delivery_address[item] = value;
			setDelivery_address({
				company: delivery_address.company,
				name: delivery_address.name,
				street: delivery_address.street,
				zip: delivery_address.zip,
				city: delivery_address.city,
				country: delivery_address.country,
				phone: delivery_address.phone,
				email: delivery_address.email,
				note: delivery_address.note,
			});
		} else if (adress === "receiver_address") {
			receiver_address[item] = value;
			setReceiver_address({
				company: receiver_address.company,
				name: receiver_address.name,
				street: receiver_address.street,
				zip: receiver_address.zip,
				city: receiver_address.city,
				country: receiver_address.country,
				phone: receiver_address.phone,
				email: receiver_address.email,
				note: receiver_address.note,
			});
		}
	};

	/* Init basic settings*/
	const setConstantSettings = () => {
		/* Gebaeudeklasse + Heizlast */
		setGebaeudeklasse({ name: _t("Benutzerdefiniert"), power_per_m2: 70, edit: true });

		/* Anlagentyp + Vorlauftemperatur, Spreizung min, Spreizung max */
		setAnlagentyp({ name: _t("Brennwert- / Niedertemperaturkessel"), input_temperature: 45, spreading_min: 5, spreading_max: 15 });

		/* Mindestens ein Verteiler pro... */
		setDistributionType(distributionTypes[1]);

		/* Randzonen */
		setSeparate_border_zones(true);

		/* Verlegemuster */
		setLayingPattern(layingPatterns[0]);

		/* usersProjectData */
		setProject_address({ company: "", name: "", street: "", zip: "", city: "", country: "", phone: "", email: "", note: "" });
		setDelivery_address({ company: "", name: "", street: "", zip: "", city: "", country: "", phone: "", email: "", note: "" });
		setReceiver_address({
			company: props.user.user?.default_address?.company,
			name: `${props.user.user?.firstname} ${props.user.user?.lastname}`,
			street: props.user.user?.default_address?.street,
			zip: props.user.user?.default_address?.zip,
			city: props.user.user?.default_address?.city,
			country: props.user.user?.default_address?.country,
			phone: props.user.user?.default_address?.phone,
			email: props.user.user?.email,
			note: props.user.user?.default_address?.note,
		});

		/* detail */
		setDetail("");
	};

	/* Systemtyp => init systemDetails dependencies */
	const setSystemtypSubdependencies = async (newSystemtyp) => {
		setLoadingSystemDetails(true);
		var newKey = newSystemtyp.key;
		/* Verteiler  */
		var newDistributors = getDistributors(newKey, FBHSystemGruppen.current);
		setDistributors(newDistributors);
		var firstDistributor = getFirstDistributor(newDistributors);
		setDistributor(firstDistributor);
		/* Verteiler Subgroups*/
		setDistributorSubGroups(getSortedBestandteile(parsedJson.current, firstDistributor.bestandteile, true));

		/* Regelung  */
		var newControlSystems = getControlSystems(newKey, FBHSystemGruppen.current);
		setControlSystems(newControlSystems);
		var firstControlSystem = getFirstControlSystem(newControlSystems);
		setControlSystem(firstControlSystem);
		/* Regelung Subgroups*/
		setControlSystemSubGroups(getSortedBestandteile(parsedJson.current, firstControlSystem.bestandteile, false));

		await setSystemSubDependencies(newSystemtyp);
	};
	/* System => init some systemDetails dependencies */
	const setSystemSubDependencies = async (newSystemtyp) => {
		setLoadingSystemDetails(true);

		/* Rohrtyp */
		var newPipes = getPipes(newSystemtyp, FBHSystemGruppen.current);
		setPipes(newPipes);
		var newPipe = getFirstPipe(newPipes, newSystemtyp);
		setPipe(newPipe);

		/* Rohrabstände */
		var distances = await getPipeDistances(newSystemtyp, newPipe, Cookies.get("language"));
		setPipeDistances(distances);
		setPipeDistanceMin(getPipeDistance(distances, 0));
		setPipeDistanceMax(getPipeDistance(distances, distances.length - 1));

		/* Estrich */
		var newScreedArray = getScreedArray(newSystemtyp);
		setScreedArray(newScreedArray);
		var newScreed = getFirstScreed(newScreedArray);
		setScreed(newScreed);

		/* Esstrichzusatzmittel */
		var newScreedAdditives = getScreedAdditives(newSystemtyp, newScreed, FBHSystemGruppen.current);
		setScreedAdditives(newScreedAdditives);
		setScreedAdditive(getFirstScreedAdditive(newScreedAdditives));
		setLoadingSystemDetails(false);
	};

	/* Init all Settings from Server */
	const setAllSettings_byInput = async (projectsettings, newFBHSystemGruppen) => {
		/* Projectname */
		setProjectname(projectsettings.name);
		/* Gebaeudeklasse + Heizlast */
		setGebaeudeklasse_byInput(projectsettings.power_per_m2);

		/* Anlagentyp + Vorlauftemperatur, Spreizung min, Spreizung max */
		setAnlagentyp_byInput(projectsettings.input_temperature, projectsettings.spreading_min, projectsettings.spreading_max);

		/* Systemtyp + System */
		var newSystemtypKey = projectsettings.heat_system.group;
		var newSystemKey = projectsettings.heat_system.name;
		var newSystemtyp = getSystemtyp_byInput(newSystemtypKey, newSystemKey, newFBHSystemGruppen);
		setSystemtyp(newSystemtyp);

		/* Mindestens ein Verteiler pro... */
		setDistributionType_byInput(projectsettings.distribution_per_flat, projectsettings.distribution_per_floor);

		/* Verteiler */
		var newDistributors = getDistributors(newSystemtypKey, newFBHSystemGruppen);
		setDistributors(newDistributors);
		var newDistributorKey = projectsettings.heat_system.distributor;
		var newDistributor = getDistributor_byInput(newDistributors, newDistributorKey);
		setDistributor(newDistributor);
		/* Verteiler Subgroups*/
		setDistributorSubGroups_byInput(newDistributor.bestandteile, projectsettings.heat_system.distributor_sub_groups);

		/* Regelung */
		var newControlSystems = getControlSystems(newSystemtypKey, newFBHSystemGruppen);
		setControlSystems(newControlSystems);
		var newControlSystemKey = projectsettings.heat_system.control_system;
		var newControlSystem = getControlSystem_byInput(newControlSystems, newControlSystemKey);
		setControlSystem(newControlSystem);
		/* Regelung Subgroups*/
		setControlSystemSubGroups_byInput(newControlSystem.bestandteile, projectsettings.heat_system.control_system_sub_groups);

		/* Rohrtyp  mit max. Kreislänge und  max. Druckverlust*/
		var newPipes = getPipes(newSystemtyp, newFBHSystemGruppen);
		setPipes(newPipes);
		var newPipe = getPipe_byInput(newPipes, projectsettings.heat_system.pipe, projectsettings.max_circuit_length, projectsettings.max_pressure_loss);
		setPipe(newPipe);
		/* Rohrabstände */
		var newPipedistances = await getPipeDistances(newSystemtyp, newPipe, projectsettings.language);
		setPipeDistances(newPipedistances);
		setPipeDistanceMin(getPipeDistance(newPipedistances, projectsettings.heat_system.pipe_margin_min_index));
		setPipeDistanceMax(getPipeDistance(newPipedistances, projectsettings.heat_system.pipe_margin_max_index));
		/* Randzonen */
		setSeparate_border_zones(projectsettings.separate_border_zones);

		/* Verlegemuster */
		handleChange_layingPattern(projectsettings.heat_system.laying_pattern);

		/* Estrich */
		var newScreedArray = getScreedArray(newSystemtyp);
		setScreedArray(newScreedArray);
		var newScreed = getScreed_byInput(newScreedArray, projectsettings.heat_system.screed);
		if (!newScreed && newScreedArray && newScreedArray.length) {
			newScreed = newScreedArray[0];
		}
		setScreed(newScreed);
		/* Estrich-Zusatzmittel */
		var newScreedAdditives = getScreedAdditives(newSystemtyp, newScreed, newFBHSystemGruppen);
		setScreedAdditives(newScreedAdditives);
		var newScreedAdditive = getScrredAdditve_byInput(newScreedAdditives, projectsettings.heat_system.screed_additive);
		setScreedAdditive(newScreedAdditive);
		setLoadingSystemDetails(false);

		/* usersProjectData */
		setProject_address(projectsettings.project_address);
		setDelivery_address(projectsettings.delivery_address);
		setReceiver_address(projectsettings.receiver_address);

		/* detail */
		setDetail(projectsettings.detail);
	};

	/* Save all */
	const handleClick_saveButton = async (navigate) => {
		// distributorSubGroups[item]["selected"]
		var distributor_sub_group = getDistributorSubGroupsForSaving();
		var control_system_sub_groups = getControlSystemSubGroupsForSaving();
		var dataToSubmit = {
			data: {
				name: projectname,
				detail: detail,
				power_per_m2: gebaeudeklasse.power_per_m2,
				input_temperature: anlagentyp.input_temperature,
				spreading_min: anlagentyp.spreading_min,
				spreading_max: anlagentyp.spreading_max,
				distribution_per_floor: distributionType.distribution_per_floor,
				distribution_per_flat: distributionType.distribution_per_flat,
				separate_border_zones: separate_border_zones,
				max_circuit_length: pipe.max_circuit_length,
				max_pressure_loss: pipe.max_pressure_loss,
				language: Cookies.get("language"),
				project_address: project_address,
				delivery_address: delivery_address,
				receiver_address: receiver_address,
				heat_system: {
					group: systemtyp.key,
					name: systemtyp.systemKey,
					pipe: pipe.key,
					distributor: distributor.key,
					distributor_sub_groups: distributor_sub_group,
					control_system: controlSystem.key,
					control_system_sub_groups: control_system_sub_groups,
					screed: screed?.multiPurposeHandle,
					screed_additive: screedAdditive?.key,
					pipe_margin_min_index: pipeDistanceMin.index,
					pipe_margin_max_index: pipeDistanceMax.index,
					laying_pattern: layingPattern.value,
				},
				user: props.user.user._id,
				vendor: props.vendor._id,
			},
		};
		var result;
		try {
			setLoading(true);
			if (isNewProject) {
				result = await addProjectSettings(dataToSubmit);
			} else {
				dataToSubmit.data.json_data = jsonData.current._id;
				result = await editProjectSettings(dataToSubmit, projectId);
			}
			navigate("/project/" + result._id);
		} catch (e) {
			setLoading(false);
			//TODO error message
			console.error(e);
		}
	};
	const handleClick_goToProjektButton = async (navigate) => {
		navigate("/project/" + projectId);
	};
	const SaveCancelComponent = (
		<Grid>
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					handleClick_saveButton(navigate);
				}}
			>
				{_t("Speichern")}
			</Button>
			{!isNewProject && (
				<Button
					variant="contained"
					color="inherit"
					onClick={() => {
						handleClick_goToProjektButton(navigate);
					}}
				>
					{_t("Zum Projekt")}
				</Button>
			)}
		</Grid>
	);

	/* use effect when loaded */
	const [loading, setLoading] = React.useState(true);
	const [loadingSystemDetails, setLoadingSystemDetails] = React.useState(true);
	const [openModal, setOpenModal] = React.useState(false);
	const [modalText, setModalText] = React.useState({ title: "", text: "" });
	React.useEffect(() => {
		async function fetchData() {
			try {
				var newFBHSystemGruppen = undefined;
				if (isNewProject) {
					let j = await getJsonData();
					jsonData.current = j;
					parsedJson.current = JSON.parse(j.data);
					setConstantSettings();
					newFBHSystemGruppen = getValidSystemGroups(parsedJson.current && parsedJson.current["FBHSystemGruppen"] ? parsedJson.current["FBHSystemGruppen"] : null);
					FBHSystemGruppen.current = newFBHSystemGruppen;
					var newSystemtyp = getFirstSystemtyp();
					setSystemtyp(newSystemtyp);
					await setSystemtypSubdependencies(newSystemtyp);
				} else {
					var projectsettings = await getProjectSettings(projectId);
					jsonData.current = projectsettings.json_data;
					parsedJson.current = JSON.parse(projectsettings.json_data.data);
					newFBHSystemGruppen = getValidSystemGroups(parsedJson.current && parsedJson.current["FBHSystemGruppen"] ? parsedJson.current["FBHSystemGruppen"] : null);
					FBHSystemGruppen.current = newFBHSystemGruppen;
					await setAllSettings_byInput(projectsettings, newFBHSystemGruppen);
				}
				setLoading(false);
			} catch (e) {
				setModalText({
					title: _t("Fehlermeldung"),
					text: _t(e.message),
				});
				setOpenModal(true);
			}
		}
		fetchData();
	}, []);

	return (
		<Layout>
			{openModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={() => {
						navigate("/projectlist");
					}}
					title={modalText.title}
					text={modalText.text}
				/>
			)}
			{loading && <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />}
			{!loading && (
				<Grid item xs={12} component={ProjectSettingsStyles}>
					<Grid item container justifyContent="space-between" sx={GridPadding}>
						<Typography component="h5" variant="h5">
							{_t("Projekteinstellungen")}
						</Typography>
						{SaveCancelComponent}
					</Grid>
					<h5>{_t("Projektname")}</h5>
					<Grid container direction="row" item md={4}>
						<TextField
							variant="outlined"
							//size="small"
							value={projectname}
							onChange={(event) => {
								handleChange_projectname(event.target.value);
							}}
							onClick={selectInput}
						/>
					</Grid>

					<h5>{_t("Grundeinstellungen")}</h5>
					<Grid container direction="row" item xs={12} spacing={2}>
						<Grid item xs={12} sm={12} md={4}>
							<Grid sx={GridPadding}>
								<TextField
									select
									label={_t("Gebäudeklasse")}
									variant="outlined"
									//size="small"
									value={gebaeudeklasse.name}
									onChange={(event) => {
										handleChange_gebaeudeklasse(event);
									}}
								>
									{gebaeudeklassen.map((gebaeudeklasse) => (
										<MenuItem key={gebaeudeklasse.name} value={gebaeudeklasse.name} name={gebaeudeklasse.name}>
											{gebaeudeklasse.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid sx={GridPadding}>
								<TextField
									type="number"
									label={_t("Spezifische Heizlast") + " (W/m²)"}
									/*error label="error" helperText="Incorrect entry."*/
									variant="outlined"
									//size="small"
									inputProps={{ min: 0 }}
									// {gebaeudeklasse.name ==}
									value={gebaeudeklasse.power_per_m2}
									onClick={selectInput}
									onWheel={(event) => {
										event.target.blur();
									}}
									onChange={(event) => {
										handleChange_heizlast(event);
									}}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grid sx={GridPadding}>
								<TextField
									select
									label={_t("Anlagentyp")}
									variant="outlined"
									//size="small"
									value={anlagentyp.name}
									onChange={(event) => {
										handleChange_anlagentyp(event.target.value);
									}}
								>
									{anlagentypen.map((anlagentyp) => (
										<MenuItem key={anlagentyp.name} value={anlagentyp.name} name={anlagentyp.name}>
											{anlagentyp.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid sx={GridPadding}>
								<TextField
									type="number"
									label={_t("Vorlauftemperatur (°C)")}
									variant="outlined"
									//size="small"
									inputProps={{ min: 0 }}
									value={anlagentyp.input_temperature}
									disabled={!anlagentyp.edit}
									onClick={selectInput}
									onWheel={(event) => {
										event.target.blur();
									}}
									onChange={(event) => {
										handleChange_inputTemperature(event);
									}}
								/>
							</Grid>
							<Grid container direction="row" spacing={1} sx={GridPadding}>
								<Grid item xs={6}>
									<TextField
										type="number"
										label={_t("Spreizung min (K)")}
										variant="outlined"
										//size="small"
										inputProps={{ min: 0 }}
										value={anlagentyp.spreading_min}
										disabled={!anlagentyp.edit}
										onClick={selectInput}
										onWheel={(event) => {
											event.target.blur();
										}}
										onChange={(event) => {
											handleChange_spreadingMin(event);
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										type="number"
										label={_t("Spreizung max (K)")}
										variant="outlined"
										//size="small"
										inputProps={{ min: 0 }}
										value={anlagentyp.spreading_max}
										disabled={!anlagentyp.edit}
										onClick={selectInput}
										onWheel={(event) => {
											event.target.blur();
										}}
										onChange={(event) => {
											handleChange_spreadingMax(event);
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grid sx={GridPadding}>
								<TextField
									select
									label={_t("Systemtyp")}
									variant="outlined"
									//size="small"
									value={systemtyp.key}
									onChange={(event) => {
										handleChange_systemtyp(event);
									}}
									className="MenuWithImage"
								>
									{Object.keys(FBHSystemGruppen.current).map((key) => {
										if (FBHSystemGruppen.current[key]["SystemSets"] && Object.keys(FBHSystemGruppen.current[key]["SystemSets"]).length > 0) {
											return (
												<MenuItem key={key} value={key} name={key} className="MuiMenuItem-root">
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
														}}
													>
														<ListItemIcon>
															<Avatar
																variant="square"
																alt={" "}
																src={
																	FBHSystemGruppen.current[key]["SystemBMP"][Object.keys(FBHSystemGruppen.current[key]["SystemBMP"])[0]]
																		? "https://assets.linear.eu/" +
																			props.vendor.postfix +
																			"/images/" +
																			FBHSystemGruppen.current[key]["SystemBMP"][Object.keys(FBHSystemGruppen.current[key]["SystemBMP"])[0]] +
																			".jpg"
																		: logo
																}
																imgProps={{ style: { objectFit: "contain" } }}
															/>
														</ListItemIcon>
														<ListItemText
															primaryTypographyProps={{
																style: {
																	whiteSpace: "nowrap",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																},
															}}
															primary={FBHSystemGruppen.current[key].display}
														/>
													</div>
												</MenuItem>
											);
										}
										return null;
									})}
								</TextField>
							</Grid>
							{systemtyp.systemKey && (
								<Grid sx={GridPadding}>
									<TextField
										select
										label={_t("System")}
										variant="outlined"
										//size="small"
										value={systemtyp.systemKey}
										onChange={(event) => {
											handleChange_system(event);
										}}
										className="MenuWithImage"
									>
										{Object.keys(systemtyp.systemSets).map((item) => (
											<MenuItem key={item} value={item} name={item}>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													<ListItemAvatar>
														<Avatar
															variant="square"
															alt={" "}
															src={
																FBHSystemGruppen.current[systemtyp.key]["SystemBMP"][systemtyp.systemKey]
																	? "https://assets.linear.eu/" +
																		props.vendor.postfix +
																		"/images/" +
																		FBHSystemGruppen.current[systemtyp.key]["SystemBMP"][systemtyp.systemKey] +
																		".jpg"
																	: logo
															}
															imgProps={{ style: { objectFit: "contain" } }}
														/>
													</ListItemAvatar>
													<ListItemText
														primaryTypographyProps={{
															style: {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
															},
														}}
														primary={systemtyp.systemSets[item].display}
													/>
												</div>
											</MenuItem>
										))}
									</TextField>
								</Grid>
							)}
							<Grid sx={GridPadding}>
								<TextField
									select
									label={_t("Rohrtyp")}
									variant="outlined"
									//size="small"
									value={pipe.key}
									onChange={(event) => {
										handleChange_Pipe(event);
									}}
								>
									{Object.keys(pipes).map((item) => (
										<MenuItem key={item} value={item}>
											{pipes[item].display}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>

					{loadingSystemDetails && (
						<Grid container justifyContent="center">
							<CircularProgress />
						</Grid>
					)}
					{!loadingSystemDetails && (
						<Grid container direction="row" item xs={12} spacing={2}>
							<Grid item xs={12} sm={12} md={4}>
								<h5>{_t("Systemdetails")}</h5>
								<Grid sx={GridPadding}>
									<TextField
										select
										label={_t("Verteiler")}
										variant="outlined"
										//size="small"
										value={distributor.key}
										onChange={(event) => {
											handleChange_distributor(event);
										}}
									>
										{Object.keys(distributors).map((item) => (
											<MenuItem key={item} value={item}>
												{distributors[item].display}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								{Object.keys(distributorSubGroups).map((item) => (
									<Grid sx={GridPadding} key={item}>
										<TextField
											/*error label="error" helperText="Incorrect entry."*/
											// select={}
											select
											label={_t("Verteiler Option")}
											variant="outlined"
											//size="small"
											value={distributorSubGroups[item]["selected"]}
											disabled={distributorSubGroups[item]["oneSelective"]}
											onChange={(event) => {
												handleChange_distributorSubGroup(event, item);
											}}
											className="MenuWithImage"
										>
											{Object.keys(distributorSubGroups[item]).map((subitem) => {
												if (subitem !== "selected") {
													return (
														<MenuItem key={subitem} value={subitem} name={subitem}>
															<div
																style={{
																	flexDirection: "row",
																	display: "flex",
																	alignItems: "center",
																	width: "100%",
																}}
															>
																<ListItemIcon>
																	<Avatar
																		variant="square"
																		alt={" "}
																		src={
																			distributorSubGroups[item][subitem]["image"]
																				? "https://assets.linear.eu/" +
																					props.vendor.postfix +
																					"/images/" +
																					distributorSubGroups[item][subitem]["image"]
																				: logo
																		}
																		imgProps={{ style: { objectFit: "contain" } }}
																	/>
																</ListItemIcon>
																<ListItemText
																	primaryTypographyProps={{
																		style: {
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		},
																	}}
																	primary={distributorSubGroups[item][subitem].name}
																/>
															</div>
														</MenuItem>
													);
												}
												return null;
											})}
										</TextField>
									</Grid>
								))}
							</Grid>
							<Grid item xs={12} sm={12} md={4}>
								<h5 className="hideOnSmall">hideOnSmall</h5>
								<Grid sx={GridPadding}>
									<TextField
										select
										label={_t("Regelung")}
										variant="outlined"
										//size="small"
										value={controlSystem.key}
										onChange={(event) => {
											handleChange_controlSystem(event);
										}}
									>
										{Object.keys(controlSystems).map((item) => (
											<MenuItem key={item} value={item}>
												{controlSystems[item].display}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								{Object.keys(controlSystemSubGroups).map((item) => (
									<Grid sx={GridPadding} key={item}>
										<TextField
											/*error label="error" helperText="Incorrect entry."*/
											// select={}
											select
											label={_t("Regelung Option")}
											variant="outlined"
											//size="small"
											value={controlSystemSubGroups[item]["selected"]}
											disabled={controlSystemSubGroups[item]["oneSelective"]}
											onChange={(event) => {
												handleChange_controlSystemSubGroup(event, item);
											}}
											className="MenuWithImage"
										>
											{Object.keys(controlSystemSubGroups[item]).map((subitem) => {
												if (subitem !== "selected") {
													return (
														<MenuItem key={subitem} value={subitem} name={subitem}>
															<div
																style={{
																	flexDirection: "row",
																	display: "flex",
																	alignItems: "center",
																	width: "100%",
																}}
															>
																<ListItemIcon>
																	<Avatar
																		variant="square"
																		alt={" "}
																		src={
																			controlSystemSubGroups[item][subitem]["image"]
																				? "https://assets.linear.eu/" +
																					props.vendor.postfix +
																					"/images/" +
																					controlSystemSubGroups[item][subitem]["image"]
																				: logo
																		}
																		imgProps={{ style: { objectFit: "contain" } }}
																	/>
																</ListItemIcon>
																<ListItemText
																	primaryTypographyProps={{
																		style: {
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		},
																	}}
																	primary={controlSystemSubGroups[item][subitem].name}
																/>
															</div>
														</MenuItem>
													);
												}
												return null;
											})}
										</TextField>
									</Grid>
								))}
								{screedArray && (
									<Grid sx={GridPadding}>
										<TextField
											select
											label={_t("Estrich")}
											variant="outlined"
											//size="small"
											value={screed.display}
											onChange={(event) => {
												handleChangeScreed(event);
											}}
										>
											{screedArray.map((item) => {
												return (
													<MenuItem key={item.display} value={item.display}>
														{item.display}
													</MenuItem>
												);
											})}
										</TextField>
									</Grid>
								)}
								{screedArray && screedAdditives && (
									<Grid sx={GridPadding}>
										<TextField
											select
											label={_t("Estrichzusatzmittel")}
											variant="outlined"
											//size="small"
											value={screedAdditive.key}
											onChange={(event) => {
												handleChange_screedAdditive(event);
											}}
										>
											{Object.keys(screedAdditives).map((item) => (
												<MenuItem key={item} value={item}>
													{screedAdditives[item]["display"]}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}
							</Grid>
							<Grid item xs={12} sm={12} md={4}>
								<h5>{_t("Auslegungsparameter")}</h5>
								<Grid sx={GridPadding}>
									<TextField
										select
										label={_t("Mindestens ein Verteiler pro...")}
										variant="outlined"
										//size="small"
										value={distributionType.value}
										onChange={(event) => {
											handleChange_distributionType(event);
										}}
									>
										{distributionTypes.map((item) => (
											<MenuItem key={item.value} value={item.value}>
												{item.name}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid container direction="row" spacing={1} sx={GridPadding}>
									<Grid item xs={6}>
										{pipeDistances.length > 0 && (
											<TextField
												select
												label={_t("Rohrabstand min. (cm)")}
												variant="outlined"
												//size="small"
												value={pipeDistanceMin.index}
												onChange={(event) => {
													handleChange_pipeDistanceMin(event);
												}}
											>
												{pipeDistances.map((item, index) => {
													return (
														<MenuItem key={item._id} value={index}>
															{(item.margin / 10).toFixed(1)}
														</MenuItem>
													);
												})}
											</TextField>
										)}
									</Grid>
									<Grid item xs={6}>
										{pipeDistances.length > 0 && (
											<TextField
												select
												label={_t("Rohrabstand max. (cm)")}
												variant="outlined"
												//size="small"
												value={pipeDistanceMax.index}
												onChange={(event) => {
													handleChange_pipeDistanceMax(event);
												}}
											>
												{pipeDistances.map((item, index) => {
													return (
														<MenuItem key={item._id} value={index}>
															{(item.margin / 10).toFixed(1)}
														</MenuItem>
													);
												})}
											</TextField>
										)}
									</Grid>
								</Grid>
								<Grid sx={GridPadding}>
									<TextField
										type="number"
										label={_t("Max. Kreislänge (m)") + " ( Max: " + pipe.maxMax_circuit_length + " )"}
										/*error label="error" helperText="Incorrect entry."*/
										variant="outlined"
										//size="small"
										inputProps={{ min: 0, max: pipe.maxMax_circuit_length }}
										value={pipe.max_circuit_length}
										onClick={selectInput}
										onWheel={(event) => {
											event.target.blur();
										}}
										onChange={(event) => {
											handleChange_circuitLength(event);
										}}
									/>
								</Grid>
								<Grid sx={GridPadding}>
									<TextField
										type="number"
										label={_t("Max. Druckverlust (mbar)") + " ( Max: " + pipe.maxMax_pressure_loss + "  )"}
										/*error label="error" helperText="Incorrect entry."*/
										variant="outlined"
										//size="small"
										inputProps={{ min: 0, max: pipe.maxMax_pressure_loss }}
										value={pipe.max_pressure_loss}
										onClick={selectInput}
										onWheel={(event) => {
											event.target.blur();
										}}
										onChange={(event) => {
											handleChange_pressureLoss(event);
										}}
									/>
								</Grid>
								<Grid sx={GridPadding}>
									<TextField
										select
										label={_t("Randzonen")}
										variant="outlined"
										//size="small"
										value={separate_border_zones}
										onChange={(event) => {
											setSeparate_border_zones(event.target.value);
										}}
									>
										<MenuItem value={true}>{_t("Randzonen als eigenständige Kreise")}</MenuItem>
										<MenuItem value={false}>{_t("Randzonen als nicht eigenständige Kreise")}</MenuItem>
									</TextField>
								</Grid>
								<Grid sx={GridPadding}>
									<TextField
										select
										label="Verlegemuster"
										variant="outlined"
										value={layingPattern.value}
										onChange={(event) => {
											handleChange_layingPattern(event.target.value);
										}}
									>
										{layingPatterns.map((item) => {
											return (
												<MenuItem key={item.value} value={item.value}>
													{item.name}
												</MenuItem>
											);
										})}
									</TextField>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Typography component="h6" variant="h6">
						{_t("Projektdaten (für Deckblätter in PDFs)")}
					</Typography>
					<Grid container direction="row" item xs={12} spacing={2}>
						<Grid item xs={12} sm={12} md={4}>
							<h5>{_t("Projektadresse")}</h5>
							{Object.keys(project_address).map((item) => {
								if (item !== "_id") {
									return (
										<TextField
											key={"project_address," + item}
											label={labelForAdressItem[item]}
											variant="standard"
											size="small"
											multiline
											minRows={item === "note" ? 4 : 1}
											value={project_address[item]}
											onChange={(event) => {
												handleChange_usersProjectData("project_address", item, event.target.value);
											}}
										/>
									);
								}
								return null;
							})}
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<h5>{_t("Kundenadresse")}</h5>
							{Object.keys(delivery_address).map((item) => {
								if (item !== "_id") {
									return (
										<TextField
											key={"delivery_address," + item}
											label={labelForAdressItem[item]}
											variant="standard"
											size="small"
											multiline
											minRows={item === "note" ? 4 : 1}
											value={delivery_address[item]}
											onChange={(event) => {
												handleChange_usersProjectData("delivery_address", item, event.target.value);
											}}
										/>
									);
								}
								return null;
							})}
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<h5>{_t("Erfasser")}</h5>
							{Object.keys(receiver_address).map((item) => {
								if (item !== "_id") {
									return (
										<TextField
											key={"receiver_address," + item}
											label={labelForAdressItem[item]}
											variant="standard"
											size="small"
											multiline
											minRows={item === "note" ? 4 : 1}
											value={receiver_address[item]}
											onChange={(event) => {
												handleChange_usersProjectData("receiver_address", item, event.target.value);
											}}
										/>
									);
								}
								return null;
							})}
						</Grid>
					</Grid>
					<Grid container direction="row" item xs={12} sx={GridPadding} style={{ paddingRight: "15px" }}>
						<TextField
							label={_t("Bemerkung")}
							variant="standard"
							size="small"
							multiline
							minRows={4}
							value={detail}
							onChange={(event) => {
								setDetail(event.target.value);
							}}
						/>
					</Grid>
					<Grid container justifyContent="flex-end">
						{SaveCancelComponent}
					</Grid>
				</Grid>
			)}
		</Layout>
	);
}

export default ProjectSettings;
