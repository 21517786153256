import React from "react";
import { Grid, Typography } from "@mui/material";

import { GridPadding } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { _t } from "../../store/translate.js";
// import Cookies from "js-cookie";

function Privacy(props) {
	

	// const lang = Cookies.get("language");

	const defaultPrivacy = (withoutAddition = false) => (
		<Grid container direction="column" sx={GridPadding}>
			<h2> Datenschutzerklärung nach der DSGVO</h2>
			<br />
			<h3> I. Name und Anschrift des Verantwortlichen</h3>
			Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher
			Bestimmungen ist die:
			<i>
				Verantwortlicher: Jürgen Frantzen
				<br />
				LINEAR GmbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				Deutschland
				<br />
				Tel.: 0241 889800
				<br />
				E-Mail:
				<a href="mailto:dsb@linear.de">dsb@linear.de</a>
				<br />
				Website:
				<a href="//www.linear.de" target="blank">
					www.linear.de
				</a>
			</i>
			<h3> II. Name und Anschrift des Datenschutzbeauftragten</h3>
			Der Datenschutzbeauftragte des Verantwortlichen ist:
			<i>
				Dipl.-Ing Dragan Stanković
				<br />
				ido stanković
				<br />
				Lütticher Straße 7
				<br />
				52064 Aachen
				<br />
				Deutschland
				<br />
				Tel.: 0241 5903360
				<br />
				E-Mail:
				<a href="mailto:info@ido-stankovic.de"> info@ido-stankovic.de</a>
				<br />
				Website:
				<a href="//www.ido-stankovic.de" target="blank">
					www.ido-stankovic.de
				</a>
			</i>
			<h3> III. Allgemeines zur Datenverarbeitung</h3>
			<h4> 1. Umfang der Verarbeitung personenbezogener Daten</h4>
			Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte
			und Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme
			gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch
			gesetzliche Vorschriften gestattet ist.
			<h4> 2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
			<section>
				Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
				EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
			</section>
			<section>
				Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6
				Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
			</section>
			<section>
				Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs.
				1 lit. c DSGVO als Rechtsgrundlage.
			</section>
			<section>
				Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich
				machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
			</section>
			<section>
				Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und
				Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
			</section>
			<h4> 3. Datenlöschung und Speicherdauer</h4>
			Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus
			erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
			Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene
			Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
			<h3> IV. Bereitstellung der Website und Erstellung von Logfiles</h3>
			<h4> 1. Beschreibung und Umfang der Datenverarbeitung</h4>
			Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten werden
			hierbei erhoben:
			<ol>
				<li> Informationen über den Browsertyp und die verwendete Version</li>
				<li> Das Betriebssystem des Nutzers</li>
				<li> Den Internet-Service-Provider des Nutzers</li>
				<li> Die IP-Adresse des Nutzers</li>
				<li> Datum und Uhrzeit des Zugriffs</li>
				<li> Websites, von denen das System des Nutzers auf unsere Internetseite gelangt </li>
				<li> Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
				Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers
				findet nicht statt.
			</ol>
			<h4> 2. Rechtsgrundlage für die Datenverarbeitung </h4>
			Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
			<h4> 3. Zweck der Datenverarbeitung </h4>
			Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss
			die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen.
			Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu
			Marketingzwecken findet in diesem Zusammenhang nicht statt. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f
			DSGVO.
			<h4> 4. Dauer der Speicherung </h4>
			Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung
			der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall.
			Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden
			Clients nicht mehr möglich ist.
			<h4> 5. Widerspruchs- und Beseitigungsmöglichkeit </h4>
			Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht
			folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
			<h3> V. Verwendung von Cookies </h3>
			<h4> a) Beschreibung und Umfang der Datenverarbeitung </h4>
			Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers
			gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
			charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.
			<section>
				Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach
				einem Seitenwechsel identifiziert werden kann.
			</section>
			<section> In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</section>
			<ol>
				<li> Um die Sitzung zu speichern</li>
				<li> Um die Auswahl des Benutzers zu speichern</li>
				<li>
					<a href="#google-analytics">Google Analytics</a>
				</li>
			</ol>
			<section> Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer ermöglichen.</section>
			<section> Auf diese Weise können folgende Daten übermittelt werden:</section>
			<section> Es folgt eine Auflistung der erhobenen Daten. Diese können beispielsweise sein:</section>
			<ol>
				<li> Eingegebene Suchbegriffe</li>
				<li> Häufigkeit von Seitenaufrufen</li>
				<li> Inanspruchnahme von Website-Funktionen</li>
			</ol>
			<section>
				Die auf diese Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht
				mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten der Nutzer gespeichert.
			</section>
			<section>
				Beim Aufruf unserer Website werden die Nutzer durch ein Infobanner über die Verwendung von Cookies zu Analysezwecken informiert und auf diese Datenschutzerklärung
				verwiesen. Es erfolgt in diesem Zusammenhang auch ein Hinweis darauf, wie die Speicherung von Cookies in den Browsereinstellungen unterbunden werden kann.
			</section>
			Beim Aufruf unserer Website wird der Nutzer über die Verwendung von Cookies zu Analysezwecken informiert und seine Einwilligung zur Verarbeitung der in diesem
			Zusammenhang verwendeten personenbezogenen Daten eingeholt. In diesem Zusammenhang erfolgt auch ein Hinweis auf diese Datenschutzerklärung.
			<h4> b) Rechtsgrundlage für die Datenverarbeitung </h4>
			Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
			<section> Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f DSGVO.</section>
			<section>
				Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies zu Analysezwecken ist bei Vorliegen einer diesbezüglichen Einwilligung
				des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
			</section>
			<h4> c) Zweck der Datenverarbeitung</h4>
			<section>
				Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können
				ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.
			</section>
			<section> Für folgende Anwendungen benötigen wir Cookies:</section>
			<ol>
				<li> Um die Sitzung zu speichern</li>
				<li> Um die Auswahl des Benutzers zu speichern</li>
				<li>
					<a href="#google-analytics">Google Analytics</a>
				</li>
			</ol>
			<section> Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.</section>
			<section>
				Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer Website und ihre Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir, wie
				die Website genutzt wird und können so unser Angebot stetig optimieren.
			</section>
			In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.
			<h4> e) Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit </h4>
			Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die
			Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits
			gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht
			mehr alle Funktionen der Website vollumfänglich genutzt werden.
			<h3> VI. Registrierung</h3>
			<h4> 1. Beschreibung und Umfang der Datenverarbeitung</h4>
			Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren. Die Daten werden dabei in eine Eingabemaske
			eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte findet statt. Bei der Registrierung auf unserer Website wird der Nutzer über die
			Weitergabe seiner Daten informiert und seine Einwilligung zur Verarbeitung der in diesem Zusammenhang verwendeten personenbezogenen Daten eingeholt.
			<section> Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:</section>
			<section> Im Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert:</section>
			{withoutAddition ? (
				<ol>
					<li> Die IP-Adresse des Nutzers</li>
					<li> Datum und Uhrzeit der Registrierung</li>
					<li> E-Mail </li>
				</ol>
			) : (
				<ol>
					<li> Die IP-Adresse des Nutzers</li>
					<li> Datum und Uhrzeit der Registrierung</li>
					<li> Vorname </li>
					<li> Nachname </li>
					<li> E-Mail </li>
					<li> Funktion</li>
					<li> Firma</li>
					<li> Straße</li>
					<li> Postleitzahl</li>
					<li> Stadt</li>
					<li> Land</li>
				</ol>
			)}
			Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers zur Verarbeitung dieser Daten eingeholt.
			<h4> 2. Rechtsgrundlage für die Datenverarbeitung </h4>
			Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Dient die Registrierung der Erfüllung eines
			Vertrages, dessen Vertragspartei der Nutzer ist oder der Durchführung vorvertraglicher Maßnahmen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung der Daten Art.
			6 Abs. 1 lit. b DSGVO.
			<h4> 3. Zweck der Datenverarbeitung</h4>
			Eine Registrierung des Nutzers ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website erforderlich.
			<section>
				Insbesondere die Möglichkeit des kostenlosen Downloads von Produktdatensätzen erfordert die Erfassung Personenbezogener Daten und deren Weitergabe an den jeweiligen
				Hersteller.
			</section>
			<section> Die Weitergabe der Daten erfolgt nur an den Hersteller der Heruntergeladenen Dateien</section>
			<h4> 4. Dauer der Speicherung</h4>
			Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist für die während des Registrierungsvorgangs
			erhobenen Daten der Fall, wenn die Registrierung auf unserer Internetseite aufgehoben oder abgeändert wird.
			<h4> 5. Widerspruchs- und Beseitigungsmöglichkeit</h4>
			Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit abändern lassen. Die Änderungen der
			Daten ist jederzeit über das jeweilige Nutzerprofil vom User möglich.
			<h3>#google-analytics VII. Webanalyse durch Google Analytics</h3>
			<h4> 1. Umfang der Verarbeitung personenbezogener Daten</h4>
			Google Analytics ist ein Webanalysedienst, der von Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, United States) betrieben und bereitgestellt wird.
			Google verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und verpflichtet sich vertraglich zu Maßnahmen, um die Vertraulichkeit der verarbeiteten Daten zu
			gewährleisten. Während Ihres Website-Besuchs werden u.a. folgende Daten aufgezeichnet:
			<ol>
				<li> Aufgerufene Seiten</li>
				<li> Bestellungen inkl. des Umsatzes und der bestellten Produkte</li>
				<li> Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten und Verweildauer)</li>
				<li> Ihr ungefährer Standort (Land und Stadt)</li>
				<li> Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung möglich ist)</li>
				<li> Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung</li>
				<li> Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel Sie zu uns gekommen sind)</li>
			</ol>
			Diese Daten werden an einen Server von Google in den USA übertragen. Google beachtet dabei die Datenschutzbestimmungen des „US-Safe-Harbor“-Abkommens. Google Analytics
			speichert Cookies in Ihrem Webbrowser für die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies enthaltene eine zufallsgenerierte User-ID, mit der Sie bei
			zukünftigen Website-Besuchen wiedererkannt werden können. Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten User-ID gespeichert, was die Auswertung
			pseudonymer Nutzerprofile ermöglicht.
			<span> Sollten Sie mit der Erfassung nicht einverstanden sein, können Sie diese mit der einmaligen Installation des </span>
			<a href="//tools.google.com/dlpage/gaoptout?hl=de" target="blank">
				Browser-Add-ons zur Deaktivierung von Google Analytics
			</a>
			<span> unterbinden.</span>
			Die Software ist so eingestellt, dass die IP-Adressen nicht vollständig gespeichert werden, sondern 2 Bytes der IP-Adresse maskiert werden (Bsp.: 192.168.xxx.xxx). Auf
			diese Weise ist eine Zuordnung der gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr möglich.
			<h4> 2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
			Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 lit. f DSGVO.
			<h4> 3. Zweck der Datenverarbeitung</h4>
			Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des Surfverhaltens unserer Nutzer. Wir sind in durch die Auswertung der gewonnenen
			Daten in der Lage, Informationen über die Nutzung der einzelnen Komponenten unserer Webseite zusammenzustellen. Dies hilft uns dabei unsere Webseite und deren
			Nutzerfreundlichkeit stetig zu verbessern. In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der Daten nach Art. 6 Abs. 1 lit. f DSGVO.
			Durch die Anonymisierung der IP-Adresse wird dem Interesse der Nutzer an deren Schutz personenbezogener Daten hinreichend Rechnung getragen.
			<h4> 4. Dauer der Speicherung</h4>
			Die Daten werden gelöscht, sobald sie für unsere Aufzeichnungszwecke nicht mehr benötigt werden. Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten
			User-ID gespeichert, was die Auswertung pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 14 Monaten gelöscht. Sonstige Daten
			bleiben in aggregierter Form unbefristet gespeichert.
			<h4> 5. Widerspruchs- und Beseitigungsmöglichkeit</h4>
			Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die
			Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits
			gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht
			mehr alle Funktionen der Website vollumfänglich genutzt werden.
			<span>
				Wir bieten unseren Nutzern auf unserer Website die Möglichkeit eines Opt-Out aus dem Analyseverfahren. Hierzu müssen Sie dem entsprechenden Link folgen. Auf diese
				Weise wird ein weiteres Cookie auf ihrem System gesetzt, der unserem System signalisiert die Daten des Nutzers nicht zu speichern. Löscht der Nutzer das
				entsprechende Cookie zwischenzeitlich vom eigenen System, so muss er den Opt-Out-Cookie erneut setzten. Opt-Out ist aktivierbar mittels der einmaligen Installation
				des
			</span>
			<a href="//tools.google.com/dlpage/gaoptout?hl=de" target="blank">
				Browser-Add-ons zur Deaktivierung von Google Analytics.
			</a>
			<h3> VIII. Rechte der betroffenen Person</h3>
			Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
			<h4> 1. Auskunftsrecht</h4>
			<section>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.</section>
			<section> Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:</section>
			<ol>
				<li> die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
				<li> die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
				<li>
					die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
				</li>
				<li>
					die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die
					Festlegung der Speicherdauer;
				</li>
				<li>
					das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den
					Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
				</li>
				<li> das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
				<li> alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</li>
				<li>
					das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen –
					aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene
					Person.
				</li>
			</ol>
			Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation
			übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu
			werden.
			<h4> 2. Recht auf Berichtigung</h4>
			Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen,
			unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
			<h4> 3. Recht auf Einschränkung der Verarbeitung</h4>
			Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
			<ol>
				<li>
					wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
					personenbezogenen Daten zu überprüfen;
				</li>
				<li>
					die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen
					Daten verlangen;
				</li>
				<li>
					der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder
					Verteidigung von Rechtsansprüchen benötigen, oder
				</li>
				<li>
					wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen
					gegenüber Ihren Gründen überwiegen.
				</li>
			</ol>
			<section>
				Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung
				oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
				Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
			</section>
			<section>
				Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung
				aufgehoben wird.
			</section>
			<h4> 4. Recht auf Löschung</h4>
			<h5> a) Löschungspflicht</h5>
			Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet,
			diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
			<ol>
				<li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
				<li>
					Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer
					anderweitigen Rechtsgrundlage für die Verarbeitung.
				</li>
				<li>
					Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder
					Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
				</li>
				<li> Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet. </li>
				<li>
					Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
					Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
				</li>
				<li> Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>
			</ol>
			<h5> b) Information an Dritte</h5>
			Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft
			er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung
			Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen
			personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
			<h5> c) Ausnahmen</h5>
			Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
			<ol>
				<li> zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
				<li>
					zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt,
					erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
					übertragen wurde;
				</li>
				<li> aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</li>
				<li>
					für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO,
					soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt,
					oder
				</li>
				<li> zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
			</ol>
			<h4> 5. Recht auf Unterrichtung</h4>
			<section>
				Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
				Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung
				mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
			</section>
			<section> Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</section>
			<h4> 6. Recht auf Datenübertragbarkeit</h4>
			Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und
			maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
			personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
			<ol>
				<li>
					die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO
					beruht und
				</li>
				<li> die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
			</ol>
			<section>
				In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen
				Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
			</section>
			<section>
				Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im
				öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
			</section>
			<h4> 7. Widerspruchsrecht</h4>
			<section>
				Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten,
				die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
			</section>
			<section>
				Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung
				nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
			</section>
			<section>
				Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
				der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
				Verbindung steht.
			</section>
			<section>
				Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
			</section>
			<section>
				Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht
				mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
			</section>
			<h4> 8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>
			Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund
			der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
			<h4> 9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h4>
			<section>
				Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden,
				die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
			</section>
			<section> Dies gilt nicht, wenn die Entscheidung</section>
			<ol>
				<li> für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
				<li>
					aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene
					Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
				</li>
				<li> mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
			</ol>
			<section>
				Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a
				oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
			</section>
			<section>
				Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu
				wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung
				der Entscheidung gehört.
			</section>
			<h4> 10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
			<section>
				Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde,
				insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
				Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
			</section>
			<section>
				Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich
				der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
			</section>
			Stand 25.05.2018
		</Grid>
	);

	// const vendorList = {
	// 	buderus_de: defaultPrivacy(), //TODO check
	// 	henco: defaultPrivacy(), //TODO check
	// 	henco_nl: defaultPrivacy(), //TODO check
	// 	ivt_de: defaultPrivacy(true),
	// 	kermi_de: defaultPrivacy(),
	// 	"p&m_de": defaultPrivacy(),
	// 	roth: defaultPrivacy(),
	// 	schuetz: defaultPrivacy(), //TODO check
	// 	somatherm: defaultPrivacy(), //TODO check
	// 	viega: defaultPrivacy(), //TODO check
	// 	viessmann: defaultPrivacy(), //TODO check
	// };

	return (
		<Layout>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" sx={GridPadding}>
					<Typography component="h4" variant="h4">
						{_t("Datenschutz")}
					</Typography>
				</Grid>
				{/* {vendorList[props.vendor.postfix === "henco_nl" ? props.vendor.postfix : props.vendor.postfix + "_" + lang]} */}
				{props.vendor.postfix === "ivt" ? defaultPrivacy(true) : defaultPrivacy()}
			</Grid>
		</Layout>
	);
}

export default Privacy;
