import axios from "axios";
import { USER_SERVER } from "../../config.js";
import { LOGIN_USER } from "../../store/types.js";

export async function resetPassword(email) {
	try {
		var response = await axios({
			method: "get",
			url: `${USER_SERVER}/resetPassword/${email}`,
			baseURL: "/",
		});
		return response;
	} catch (error) {
		return error.response?.data;
	}
}

export async function verifyResetPasswordToken(email, token) {
	try {
		var response = await axios({
			method: "get",
			url: `${USER_SERVER}/verifyResetPasswordToken/${email}/${token}`,
			baseURL: "/",
		});
		return response;
	} catch (error) {
		return error.response?.data;
	}
}

export async function updatePassword(email, password, token) {
	return {
		type: LOGIN_USER,
		payload: await axios({
			method: "put",
			url: `${USER_SERVER}/updatePassword`,
			data: { email, password, token },
			baseURL: "/",
		})
			.then((response) => {
				return response?.data;
			})
			.catch((err) => {
				return err;
			}),
	};
}
