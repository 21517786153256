import React from "react";
import { Link, Typography } from "@mui/material";

export function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://www.linear.eu/" target="_blank" rel="noopener noreferrer">
				LINEAR GmbH{" "}
			</Link>
			{new Date().getFullYear()}
		</Typography>
	);
}
