import axios from "axios";
import { USER_SERVER } from "../../config";
import { LOGIN_USER, LOGOUT_USER } from "../../store/types";

export function updateProfile(id, dataToSubmit) {
	const request = axios({
		method: "put",
		url: `${USER_SERVER}/update/${id}`,
		baseURL: "/",
		data: dataToSubmit,
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: LOGIN_USER,
		payload: request,
	};
}
export function changePassword(id, dataToSubmit) {
	const request = axios({
		method: "put",
		url: `${USER_SERVER}/changePassword/${id}`,
		baseURL: "/",
		data: dataToSubmit,
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: LOGOUT_USER,
		payload: request,
	};
}

export function deleteAccount(id) {
	const request = axios({
		method: "delete",
		url: `${USER_SERVER}/removeById/${id}`,
		baseURL: "/",
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: LOGOUT_USER,
		payload: request,
	};
}
