import { CircularProgress } from "@mui/material";

const LoadingOverlay = () => {
	return (
		<div
			style={{
				position: "absolute",
				zIndex: 9999,
				width: "100%",
				height: "100%",
			}}
		>
			<div style={{ position: "relative", width: "100%", height: "100%" }}>
				<div style={{ background: "white", width: "100%", height: "100%", position: "absolute", opacity: 0.5 }} />
				<div
					style={{
						zIndex: 9999,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</div>
			</div>
		</div>
	);
};

export default LoadingOverlay;
