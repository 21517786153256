import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "core-js";

import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import * as serviceWorker from "./services/serviceWorker";
import { Auth, ErrorAuth, SSOAuth, VendorAuth } from "./store/middleware/auth";

// pages for this product
import HomePage from "./components/home_login/homeView.js";
import RegisterPage from "./components/register/registerView.js";
import VerifyPage from "./components/verify/verifyView.js";
import ResetPassword from "./components/resetPassword/resetPasswordView.js";
import AccountPage from "./components/accountProfile/accountView.js";
import ProfilePage from "./components/accountProfile/profileView.js";
import ProjectListPage from "./components/projectList/projectListView.js";
import ProjectSettingsPage from "./components/projectSettings/projectSettingsView.js";
import ProjectPage from "./components/project/projectView.js";
import MaterialPage from "./components/material/materialView.js";
import Imprint from "./components/footer/imprint.js";
import TermsOfUse from "./components/footer/termsOfUse.js";
import Privacy from "./components/footer/privacyView.js";
import AdministrationPage from "./components/administration/administrationView.js";
import PortalPage from "./components/administration/portalView.js";
import ErrorPage from "./components/error/errorView.js";
import { errorInterceptor } from "./store/errorInterceptor";
import { setDictionary } from "./store/translate";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";

import store from "./store/store.js";
import Bridge from "./components/bridge/bridgeView";

import * as Sentry from "@sentry/react";

const dsn = "https://7c5c77cbf9620323d32ea6d6749b1c49@o4505917657382912.ingest.sentry.io/4505990747848704";
Sentry.init({
	dsn: dsn,
	integrations: [
		Sentry.browserTracingIntegration({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme(
	{
		components: {
			MuiLink: {
				defaultProps: {
					underline: "hover",
				},
			},
		},
	},
	deDE
);

let persistor = persistStore(store);
errorInterceptor(store);
store.dispatch(setDictionary());

let showPortal = window.location.hostname.indexOf("localhost") >= 0 || window.location.hostname.indexOf("herokuapp") >= 0;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<Suspense fallback={<div>Loading...</div>}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<Routes>
								<Route path="/" element={<Auth component={HomePage} errorComponent={null} />} />
								<Route path="/register" element={<Auth component={RegisterPage} errorComponent={false} />} />
								<Route path="/verify/:email/:id" element={<Auth component={VerifyPage} errorComponent={false} />} />
								<Route path="/resetPassword" element={<Auth component={ResetPassword} errorComponent={false} />} />
								<Route path="/resetPassword/:email/:token" element={<Auth component={ResetPassword} errorComponent={false} />} />
								<Route path="/account" element={<Auth component={AccountPage} errorComponent={ErrorPage} />} />
								<Route path="/profile" element={<Auth component={ProfilePage} errorComponent={ErrorPage} />} />
								<Route path="/projectlist" element={<Auth component={ProjectListPage} errorComponent={ErrorPage} />} />
								<Route path="/projectsettings/:id" element={<Auth component={ProjectSettingsPage} errorComponent={ErrorPage} />} />
								<Route path="/project/:id" element={<Auth component={ProjectPage} errorComponent={ErrorPage} />} />
								<Route path="/material/:id" element={<Auth component={MaterialPage} errorComponent={ErrorPage} />} />
								<Route path="/imprint" element={<Auth component={Imprint} errorComponent={false} />} />
								<Route path="/termsOfUse" element={<Auth component={TermsOfUse} errorComponent={false} />} />
								<Route path="/privacy" element={<Auth component={Privacy} errorComponent={false} />} />
								<Route path="/administration" element={<Auth component={AdministrationPage} errorComponent={ErrorPage} adminRoute={true} />} />
								<Route path="/landing/:id" element={<VendorAuth component={HomePage} errorComponent={false} />} />
								<Route path="/sso/:token" element={<SSOAuth component={Bridge} errorComponent={ErrorPage} />} />
								<Route path="/error" element={<ErrorAuth component={ErrorPage} errorComponent={ErrorPage} />} />
								{showPortal && <Route exact path="/portal" element={<PortalPage errorComponent={false} />} />}
							</Routes>
						</ThemeProvider>
					</StyledEngineProvider>
				</Suspense>
			</BrowserRouter>
		</PersistGate>
	</Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
