import axios from "axios";
import { USER_SERVER } from "../../config.js";

export function registerUser(dataToSubmit) {
	return axios({
		method: "post",
		url: `${USER_SERVER}/register`,
		baseURL: "/",
		data: dataToSubmit,
	});
}
