import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const ProjectStyles = styled("div")(({ theme }) => ({
	flexGrow: 1,
	backgroundColor: theme.palette.background.paper,
	width: "100%",
	"& .nakedInput .MuiInput-underline:before": {
		borderBottom: "none",
	},
}));

export const AccordionStyle = {
	width: "100%",
	background: "#ececec",
	paddingBottom: "1px",
	boxShadow: "unset !important",
	"& .MuiAccordion-root": {
		background: "#f7f7f7 !important",
		boxShadow: "unset !important",
	},
	"&.MuiAccordion-root.Mui-expanded ": {
		marginTop: "1px",
	},
	"& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
		position: "sticky",
		top: "-10px",
		zIndex: 1,
	},
	"& .MuiTextField-root": {},
	"& .MuiButtonBase-root.MuiAccordionSummary-root": {
		flexDirection: "row-reverse",
		//TODO ok?
		paddingRight: "0px",
	},
	"& .MuiAccordionSummary-content": {
		padding: "0 0 0 20px",
		margin: 0,
	},
	"& .MuiAccordionDetails-root": {
		paddingRight: 0,
	},
	"& .MuiInputBase-input": { color: "black" },
};

export const ButtonWithIconStyles = {
	"& p": {
		paddingLeft: "5px",
	},
};

export const Wrapper = {
	position: "relative",
};

export const TableStyles = styled(Paper)(({ theme }) => ({
	boxShadow: "unset !important",
	// "& fieldset": {
	// 	border: "unset !important",
	// },
	"& .MuiTable-root": {
		minWidth: 1200,
		overflow: "hidden",
	},
	"& .MuiTableCell-root": { padding: "0", borderBottom: "unset" },
	"& .MuiFormControl-root.MuiTextField-root": {
		width: "100%",
	},
	// "& .MuiFormControl-root.MuiTextField-root > p": {
	// 	maxWidth: "60px",
	// },
	"& .MuiInputBase-input": {
		"& .textArea": {
			[theme.breakpoints.down("lg")]: {
				minWidth: "110px",
			},
			[theme.breakpoints.up("lg")]: {
				minWidth: "150px !important",
			},
			[theme.breakpoints.up("xl")]: {
				minWidth: "200px !important",
			},
		},
		"& .fourDigits": {
			minWidth: "53px !important",
		},
		"& .threeDigits": {
			minWidth: "43px !important",
		},
	},

	"& .MuiInputBase-input:not(.MuiSelect-select):not(.textArea)": {
		minWidth: "20px",
		// maxWidth: "40px",
		textAlign: "right",
	},
	"& .MuiInputBase-formControl": {
		fontSize: "14px",
	},
	"& .temperature::before": {
		position: "absolute",
		right: "0",
		top: "2px",
		content: "'°C'",
	},
	"& input": {
		paddingRight: "14px",
	},
	//disable input number arrows => Chrome, Safari, Edge, Opera
	"& input::-webkit-inner-spin-button": {
		WebkitAppearance: "none",
		margin: 0,
	},
	//disable input number  => Firefox
	"& input[type=number]": {
		MozAppearance: "textfield",
	},
}));
