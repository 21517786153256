import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Typography, Grid, TextField, Link, FormControlLabel, Checkbox } from "@mui/material";

import { AvatarStyled, FormStyled, GridPaperContainer, SubmitButton } from "../../mainStyle.js";
import { registerUser } from "./registerController.js";

import { useNavigate } from "react-router-dom";
import { FormatHTML, _t } from "../../store/translate.js";
import LoadingOverlay from "../loadingOverlay/loadingOverlay.js";
import LinearTextInput from "../linearTextInput/linearTextInput.js";

function RegisterPage(props) {
	const navigate = useNavigate();
	const [formError, setFormError] = React.useState(false);

	const handleSubmit = (event, navigate) => {
		event.preventDefault();
		var data = event.target.elements;
		if (data.firstName.value && data.lastName.value && data.email.value && data.password.value && data.agb.checked) {
			setLoading(true);
			registerUser({
				data: {
					firstname: data.firstName.value,
					lastname: data.lastName.value,
					email: data.email.value,
					password: data.password.value,
				},
			})
				.then((response) => {
					if (response?.status === 200 && response?.data?._id) {
						navigate("/", {
							state: { justRegistered: true },
						});
					}
					setLoading(false);
				})
				.catch((e) => {
					console.error(e);
				});
		} else {
			setFormError(true);
		}
	};

	const [loading, setLoading] = React.useState(false);
	return (
		<Grid component={GridPaperContainer} direction="column">
			<AvatarStyled>
				<LockOutlinedIcon />
			</AvatarStyled>
			<Typography component="h1" variant="h5">
				{_t("Registrieren")}
			</Typography>
			<FormStyled
				noValidate
				onSubmit={(e) => {
					handleSubmit(e, navigate);
				}}
				style={{ opacity: loading ? 0.5 : 1.0, paddingTop: 16 }}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField autoComplete="fname" name="firstName" variant="outlined" required fullWidth id="firstName" label={_t("Vorname")} autoFocus={true} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField variant="outlined" required fullWidth id="lastName" label={_t("Nachname")} name="lastName" autoComplete="lname" />
					</Grid>
					<Grid item xs={12}>
						<TextField variant="outlined" required fullWidth id="email" label={_t("Email Addresse")} name="email" autoComplete="email" />
					</Grid>
					<Grid item xs={12}>
						<LinearTextInput required label={_t("Passwort")} id="password" fullParentWidth type="password" autoComplete="current-password" />
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							error="true"
							name="agb"
							control={<Checkbox required={true} color="primary" />}
							label={FormatHTML(_t("Ich habe die allgemeinen %1 gelesen und stimme diesen zu"), <Link href="/termsOfUse">{_t("Nutzungsbedingungen")}</Link>)}
						/>
					</Grid>
					{formError && (
						<Grid item xs={12} style={{ color: "red" }}>
							{_t("Bitte alle Felder ausfüllen!")}
						</Grid>
					)}
				</Grid>

				<SubmitButton type="submit" fullWidth variant="contained" color="primary">
					{_t("Registrieren")}
				</SubmitButton>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Link
							style={{ cursor: "pointer" }}
							variant="body2"
							onClick={() => {
								props.onClickAlreadyRegistered();
							}}
						>
							{_t("Sie haben bereits ein Konto? Anmelden")}
						</Link>
					</Grid>
				</Grid>
			</FormStyled>

			{loading ? <LoadingOverlay /> : undefined}
		</Grid>
	);
}

export default RegisterPage;
