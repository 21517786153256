import axios from "axios";
import { FLAT_SERVER, FLOOR_SERVER, INSULATION_OPTION_SERVER, MATERIAL_SERVER, PROJECT_SERVER, ROOM_SERVER } from "../../config";
import { sortObjByItem } from "../../store/gloablFunctions";
import { Buffer } from "buffer";

export async function getProjectPDF(projectId) {
	try {
		var response = await axios({
			method: "get",
			responseType: "arraybuffer",
			url: `${PROJECT_SERVER}/getProjectPDF/${projectId}`,
			baseURL: "/",
		});
		return Buffer.from(response?.data, "binary").toString("base64");
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

/* Floor */
export async function getFloors(projectId) {
	try {
		var response = await axios({
			method: "get",
			url: `${FLOOR_SERVER}/getByProjectId/${projectId}`,
			baseURL: "/",
		});
		var floors = sortObjByItem(response?.data, "level");
		return floors;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function createFloor(dataToSubmit) {
	try {
		var response = await axios({
			method: "post",
			url: `${FLOOR_SERVER}/add`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function editFloor(floorId, dataToSubmit) {
	try {
		var response = await axios({
			method: "put",
			url: `${FLOOR_SERVER}/edit/${floorId}`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function duplicateFloor(floorId) {
	try {
		var response = await axios({
			method: "post",
			url: `${FLOOR_SERVER}/duplicate/${floorId}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function moveFloor(floorId, toIndex) {
	try {
		var response = await axios({
			method: "post",
			url: `${FLOOR_SERVER}/move/${floorId}/${toIndex}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function deleteFloor(floorId) {
	try {
		var response = await axios({
			method: "delete",
			url: `${FLOOR_SERVER}/removeById/${floorId}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

/* Flat */
export async function getFlats(floorId) {
	try {
		var response = await axios({
			method: "get",
			url: `${FLAT_SERVER}/getByFloorId/${floorId}`,
			baseURL: "/",
		});
		var flats = sortObjByItem(response?.data, "level");
		return flats;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function createFlat(dataToSubmit) {
	try {
		var response = await axios({
			method: "post",
			url: `${FLAT_SERVER}/add`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function editFlat(flatId, dataToSubmit) {
	try {
		var response = await axios({
			method: "put",
			url: `${FLAT_SERVER}/edit/${flatId}`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function duplicateFlat(flatId, toFloorId) {
	try {
		var response = await axios({
			method: "post",
			url: `${FLAT_SERVER}/duplicate/${flatId}/${toFloorId}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function moveFlat(flatId, toFloorId, toIndex) {
	try {
		var response = await axios({
			method: "post",
			url: `${FLAT_SERVER}/move/${flatId}/${toFloorId}/${toIndex}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function deleteFlat(flatId) {
	try {
		var response = await axios({
			method: "delete",
			url: `${FLAT_SERVER}/removeById/${flatId}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

/* Room */
export async function getRooms(flatId) {
	try {
		var response = await axios({
			method: "get",
			url: `${ROOM_SERVER}/getByFlatId/${flatId}`,
			baseURL: "/",
		});
		var rooms = sortObjByItem(response?.data, "level", true);
		return rooms;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function createRoom(dataToSubmit) {
	try {
		var response = await axios({
			method: "post",
			url: `${ROOM_SERVER}/add`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function editRoom(roomId, dataToSubmit) {
	try {
		var response = await axios({
			method: "put",
			url: `${ROOM_SERVER}/edit/${roomId}`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function editManyRooms(where, data) {
	try {
		var response = await axios({
			method: "put",
			url: `${ROOM_SERVER}/editByWhere`,
			baseURL: "/",
			data: { data, where },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function duplicateRoom(roomId, toFlatId, toIndex) {
	try {
		var response = await axios({
			method: "post",
			url: `${ROOM_SERVER}/duplicate/${roomId}/${toFlatId}/${toIndex}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function moveRoom(roomId, toFlatId, toIndex) {
	try {
		var response = await axios({
			method: "post",
			url: `${ROOM_SERVER}/move/${roomId}/${toFlatId}/${toIndex}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
export async function deleteRoom(roomId) {
	try {
		var response = await axios({
			method: "delete",
			url: `${ROOM_SERVER}/removeById/${roomId}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function fetchInsulationOptions(filter) {
	try {
		const options = { index: 1, r_value_insulation: 1 };

		var response = await axios({
			method: "get",
			url: `${INSULATION_OPTION_SERVER}/fetch`,
			baseURL: "/",
			params: {
				filter,
				options,
			},
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

/* Material */
export async function calculateMaterial(projectId) {
	try {
		var response = await axios({
			method: "get",
			url: `${MATERIAL_SERVER}/calculate/${projectId}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		//TODO what can happen?
		return error.response;
	}
}
export async function getCalculatedMaterials(projectId, minimumPackagingQuantity) {
	try {
		var response = await axios({
			method: "get",
			url: `${MATERIAL_SERVER}/getByProjectId/${projectId}/${minimumPackagingQuantity}`,
			baseURL: "/",
		});
		var materials = response?.data;
		Object.entries(materials).forEach((entry) => {
			const obj = entry[1];
			obj.sort((a, b) => (a.alternate ? -1 : 1));
		});
		return materials;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

/* WebPro */
export async function getWebPro(projectId) {
	try {
		var response = await axios({
			method: "get",
			url: `${PROJECT_SERVER}/getWebPro/${projectId}`,
			baseURL: "/",
			responseType: "stream",
		});
		var json = response.data;

		if (typeof response.data !== "string") {
			json = JSON.stringify(response.data);
		}
		const blob = new Blob([json], { type: "application/json" });
		const href = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = href;
		link.download = response.headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/^"|"$/g, "");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		return;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
