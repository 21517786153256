import axios from "axios";
import { USER_SERVER } from "../../config.js";

export async function verifyUser(email, verify_id) {
	try {
		var response = await axios({
			method: "get",
			url: `${USER_SERVER}/verify/${email}/${verify_id}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		return error.response?.data;
	}
}
