import axios from "axios";
import { JSONDATA_SERVER, PIPE_SERVER, PROJECT_SERVER } from "../../config.js";
import { createFloor } from "../project/projectController.js";
import { _t } from "../../store/translate.js";

export function getSortedBestandteile(jsonData, bestandteile, isVerteiler) {
	var KurzText = jsonData["KurzText"];
	var verteiler_sub_groups = {};
	var i, j;
	var passive_i = 100,
		passive_j = 100;
	Object.entries(bestandteile).forEach((entry) => {
		const [key, arr] = entry;
		var teil = arr[0];
		if ((teil["anzAnschluesse"] === 1 && isVerteiler) || teil["anzAnschluesse"] === 0) {
			i = teil["mainGrp"];
			if (i !== 0) {
				j = teil["subGrp"];
				if (j === 0) {
					j = passive_j;
					passive_j += 1;
				}
			} else {
				i = passive_i;
				j = 0;
				passive_i += 1;
			}
			if (!verteiler_sub_groups[i]) {
				verteiler_sub_groups[i] = {};
			}
			verteiler_sub_groups[i][j] = { key: key, name: KurzText[key], image: image_for_art_nr(jsonData, key) };
		}
	});

	Object.entries(verteiler_sub_groups).forEach((entry) => {
		const [key, arr] = entry;
		// set oneSlective if only one option
		if (Object.keys(arr).length === 1) {
			verteiler_sub_groups[key]["oneSelective"] = true;
		}
		// select first selective of all options
		verteiler_sub_groups[key]["selected"] = Object.keys(arr)[0];
	});
	return verteiler_sub_groups;
}

/* get Image */
export function image_for_art_nr(json, art_nr) {
	var img = null;
	if (json["ArtikelInfo2"][art_nr]) {
		var anbinde_nr = json["ArtikelInfo2"][art_nr]["anbindenummer"];
		if (anbinde_nr) {
			if (json["GRAFIK1A"] && json["GRAFIK1A"][anbinde_nr]) {
				img = json["GRAFIK1A"][anbinde_nr].split(".").first + ".jpg";
			}
			img = img ? img : anbinde_nr + ".jpg";
		}
		img = img ? img : art_nr + ".jpg"; // XXX prüfung ob pfad korrekt
		img = img.split(".jpg")[0].split(".JPG")[0] + ".jpg";
	} else if (json["GRAFIK1A"] && json["GRAFIK1A"][art_nr]) {
		img = json["GRAFIK1A"][art_nr];
		img = img.split(".jpg")[0].split(".JPG")[0] + ".jpg";
	}
	if (img === "undefined.jpg") {
		img = null;
	}
	return img;
}

export async function getProjectSettings(id) {
	try {
		var response = await axios({
			method: "get",
			url: `${PROJECT_SERVER}/getById/${id}`,
			baseURL: "/",
			params: { options: { populate: "json_data" } },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function getBySystemPipeVendor(dataToSubmit) {
	try {
		var response = await axios({
			method: "get",
			url: `${PIPE_SERVER}/getBySystemPipeVendor`,
			baseURL: "/",
			params: dataToSubmit,
		});
		if (response?.data && response?.data.length > 0) {
			return response?.data;
		} else {
			//TODO handle better error
			var error = "Pipe margins do not exist!";
			alert(error);
			console.error(error);
			return null;
		}
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function addProjectSettings(dataToSubmit) {
	try {
		var response = await axios({
			method: "post",
			url: `${PROJECT_SERVER}/add`,
			baseURL: "/",
			data: dataToSubmit,
		});

		var dataToSubmit_ForFloor = {
			name: _t("Erdgeschoss"),
			count: 1,
			level: 0,
			project: response?.data._id,
		};
		await createFloor(dataToSubmit_ForFloor);
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function editProjectSettings(dataToSubmit, id) {
	try {
		var response = await axios({
			method: "put",
			url: `${PROJECT_SERVER}/edit/${id}`,
			baseURL: "/",
			data: dataToSubmit,
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function getJsonData() {
	try {
		var response = await axios({
			method: "get",
			url: `${JSONDATA_SERVER}/getCurrent/`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
	}
}
