import { styled } from "@mui/system";

export const ProjectListStyles = styled("div")(({ theme }) => ({
	"& .MuiDataGrid-columnHeaders": {
		boxShadow: theme.shadows[3],
		zIndex: 1,
	},
	"& .MuiDataGrid-columnHeaders > [role='row']": {
		background: "#ededed !important",
	},
	"& .MuiDataGrid-row.Mui-odd": {
		background: "#ededef2f",
	},
	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		background: "#f7f7f7",
	},
	"& .MuiDataGrid-cell.MuiDataGrid-cellLeft": {
		cursor: "pointer",
	},
}));
