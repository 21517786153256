import axios from "axios";
import { LOGIN_USER } from "../../store/types";
import { USER_SERVER } from "../../config.js";

export function loginUser(dataToSubmit) {
	const request = axios({
		method: "post",
		url: `${USER_SERVER}/login/`,
		baseURL: "/",
		data: dataToSubmit,
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});
	return {
		type: LOGIN_USER,
		payload: request,
	};
}
