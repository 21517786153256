import { AUTH_VENDOR, DICTIONARY, ERROR_MODAL } from "../types";

const initState = { showModal: false };

export default function vendor(state = initState, action) {
	if (action && action.payload && !action.payload.isAxiosError) {
		switch (action.type) {
			case AUTH_VENDOR:
				//sso case or normal
				let vendor = action.payload.vendor && action.payload.user ? action.payload.vendor : action.payload;
				return {
					...state,
					active: vendor.active,
					address: vendor.address,
					default_language: vendor.default_language,
					email: vendor.email,
					languages: vendor.languages,
					name: vendor.name,
					no_reply_email: vendor.no_reply_email,
					postfix: vendor.postfix,
					secret: vendor.secret,
					short_form: vendor.short_form,
					title: vendor.title,
					preferences: vendor.preferences,
					_id: vendor._id,
				};
			case DICTIONARY:
				return {
					...state,
					dictionary: action.payload,
				};
			case ERROR_MODAL:
				return {
					...state,
					showModal: action?.payload?.show,
					modalTitle: action?.payload?.title,
					modalText: action?.payload?.text,
				};
			default:
				return state;
		}
	} else {
		return state;
	}
}
