import axios from "axios";
import { PROJECT_SERVER } from "../../config";

//TODO server duplicate project new date?
//TODO get Projects by current session language?
export async function getProjects(userId, params) {
	try {
		params.filter.user = userId;
		params.options = {
			// ...params.options, //TODO fix pagination, needed?
			sort: { updated_at: -1 },
			populate: {
				path: "json_data",
				select: ["_id", "created_at"],
			},
		};
		var response = await axios({
			method: "get",
			url: `${PROJECT_SERVER}/fetch`,
			baseURL: "/",
			params,
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function sendProjectToMail(projectId, email) {
	try {
		var response = await axios({
			method: "post",
			url: `${PROJECT_SERVER}/send/${projectId}/${email}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function duplicateProject(id) {
	try {
		var response = await axios({
			method: "post",
			url: `${PROJECT_SERVER}/duplicate/${id}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function deleteProject(id) {
	try {
		var response = await axios({
			method: "delete",
			url: `${PROJECT_SERVER}/removeById/${id}`,
			baseURL: "/",
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
