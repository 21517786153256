import { combineReducers } from "redux";
import user from "./user_reducer";
import vendor from "./vendor_reducer";

const rootReducer = combineReducers({
	user,
	vendor
});

export default rootReducer;
