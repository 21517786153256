import axios from "axios";
import { MATERIAL_SERVER } from "../../config";
import { Buffer } from "buffer";

//TODO something is wrong with edit on debounce?
export async function editMaterial(id, dataToSubmit) {
	try {
		var response = await axios({
			method: "put",
			url: `${MATERIAL_SERVER}/edit/${id}`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function getPdf(projectId, minimumPackagingQuantity) {
	try {
		var response = await axios({
			method: "get",
			responseType: "arraybuffer",
			url: `${MATERIAL_SERVER}/getPdf/${projectId}/${minimumPackagingQuantity}`,
			baseURL: "/",
		});
		return Buffer.from(response?.data, "binary").toString("base64");
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function downloadFile(projectId, minimumPackagingQuantity, type) {
	try {
		var response = await axios({
			method: "get",
			url: `${MATERIAL_SERVER}/get${type}/${projectId}/${minimumPackagingQuantity}`,
			baseURL: "/",
			responseType: "stream",
		});
		var downloadLink = document.createElement("a");
		downloadLink.href = `data:text/${type.toLowerCase()};charset=utf-8,` + encodeURI(response?.data);
		downloadLink.download = response.headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/^"|"$/g, "");
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		return;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
