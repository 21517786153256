import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Person from "@mui/icons-material/Person";
import { Paper, Grid, Typography, Divider, Chip } from "@mui/material";

import { AvatarStyled, FormStyled, GridImageContainer, GridPaperContainer, SubmitButton } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";

import { changePassword, deleteAccount } from "./accountProfileController.js";
import { Format, _t } from "../../store/translate.js";
import { useDispatch } from "react-redux";

import LinearTextInput from "../linearTextInput/linearTextInput.js";

function Profile(props) {
	const dispatch = useDispatch();
	// const dispatch = useDispatch();
	const navigate = useNavigate();

	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState({ title: "", text: "" });

	// const [currentMail, setCurrentMail] = useState(props.user.user.email);
	// const handleSubmit_changeMail = (event) => {
	// 	event.preventDefault();
	// 	var data = event.target.elements;
	// 	var mail = data.email.value;
	// 	console.log(mail);
	// 	//TODO changeMail
	// 	// dispatch(
	// 	// 	loginUser({
	// 	// 		data: {
	// 	// 			email: data.email.value,
	// 	// 			password: data.password.value,
	// 	// 		},
	// 	// 	})
	// 	// ).then((response) => {
	// 	// 	if (response.payload && response.payload.user) {
	// 	// 		navigate({
	// 	// 			pathname: "/",
	// 	// 		});
	// 	// 	} else {
	// 	// 		let status = response.payload.response ? response.payload.response.status : null;
	// 	// 		var title, text;
	// 	// 		if (status === 400 || status === 404) {
	// 	// 			title = _t("Benutzer ist nicht registriert");
	// 	// 			text = _t("Ihr Konto muss registriert sein.");
	// 	// 		} else if (status === 405) {
	// 	// 			title = _t("Benutzer wurde nicht verifiziert");
	// 	// 			text = _t("Sie müssen auf einen Bestätigungslink klicken, dass an Ihr Konto gesendet wurde.");
	// 	// 		} else {
	// 	// 			title = _t("Falsche Eingabe");
	// 	// 			text = _t("Das Passwort oder die E-Mail-Adresse ist falsch");
	// 	// 		}
	// 	// 		setModalText({ title, text });
	// 	// 		setOpenModal(true);
	// 	// 	}
	// 	// });
	// };

	// const [currentPassword, setCurrentPassword] = useState(props.user.user.email);
	const handleSubmit_changePassword = (event) => {
		event.preventDefault();
		var data = event.target.elements;
		var oldPassword = data.oldPassword.value;
		var newPassword = data.newPassword.value;
		var repeatPassword = data.repeatPassword.value;
		if (newPassword === repeatPassword) {
			//TODO changePassword
			dispatch(
				changePassword(props.user.user._id, {
					oldPassword,
					newPassword,
				})
			).then((response) => {
				if (response.payload) {
					var title = _t("Erfolgreich!");
					var text = response.payload;
					setModalText({ title, text });
					setOpenModal(true);
				}
			});
		} else {
			var title = _t("Passwörter stimmen nicht überein.");
			var text = _t("Bitte Passwörter erneut eingeben.");
			setModalText({ title, text });
			setOpenModal(true);
		}
	};

	const [deleteAccountModal, setDeleteAccountModal] = useState(false);
	const deleteAccountOnConfirmation = () => {
		dispatch(deleteAccount(props.user.user._id)).then((response) => {
			if (response.payload) {
				var title = _t("Erfolgreich!");
				var text = response.payload;
				setModalText({ title, text });
				setOpenModal(true);
			}
		});
	};

	// React.useEffect(async () => {}, []);

	return (
		<Layout>
			{/* {openModal && <ModalDialog vendor={props.vendor} opened={true} closed={setOpenModal} title={modalText.title} text={modalText.text} />} */}
			{deleteAccountModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={setDeleteAccountModal}
					title={Format(_t("%1 permanent löschen?"), [_t("Account ")])}
					acceptbutton={deleteAccountOnConfirmation}
				/>
			)}
			{openModal && <ModalDialog vendor={props.vendor} opened={true} closed={setOpenModal} title={modalText.title} text={modalText.text} />}
			<Grid container>
				<Grid item xs={false} sm={4} md={7} component={GridImageContainer} vendor={props.vendor.postfix} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<GridPaperContainer>
						<AvatarStyled>
							<Person />
						</AvatarStyled>
						<Typography component="h1" variant="h5">
							{_t("Mein Account")}
						</Typography>
						{/* <Grid container sx={GridPadding}>
							<Typography component="h1" variant="overline">
								{_t("Email Adresse ändern")}
							</Typography>
							<form
								component={FormStyled}
								noValidate
								onSubmit={(e) => {
									handleSubmit_changeMail(e, navigate);
								}}
							>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label={_t("Email Adresse")}
									value={currentMail}
									name="email"
									autoComplete="email"
									autoFocus
									onChange={(event) => {
										setCurrentMail(event.target.value);
									}}
								/>
								<SubmitButton type="submit" fullWidth variant="contained" color="primary">
									{_t("Email ändern")}
								</SubmitButton>
							</form>
						</Grid>
						 */}
						<Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "10px 0px 15px" }}>
							<Typography component="h1" variant="overline">
								{_t("Passwort ändern")}
							</Typography>
							<FormStyled
								noValidate
								onSubmit={(e) => {
									handleSubmit_changePassword(e, navigate);
								}}
							>
								<LinearTextInput
									variant="outlined"
									margin="normal"
									required
									fullParentWidth
									name="oldPassword"
									label={_t("Aktuelles Passwort")}
									type="password"
									id="oldPassword"
									autoComplete="current-password"
								/>
								<LinearTextInput
									variant="outlined"
									margin="normal"
									required
									fullParentWidth
									name="newPassword"
									label={_t("Neues Passwort")}
									type="password"
									id="newPassword"
									autoComplete="new-password"
								/>
								<LinearTextInput
									variant="outlined"
									margin="normal"
									required
									fullParentWidth
									name="repeatPassword"
									label={_t("Neues Passwort Wiederholung")}
									type="password"
									id="repeatPassword"
									autoComplete="new-password"
								/>
								{/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
								<SubmitButton type="submit" fullWidth variant="contained" color="primary">
									{_t("Passwort ändern")}
								</SubmitButton>
							</FormStyled>
						</Grid>
						<div style={{ width: "90%" }}>
							<Divider>
								<Chip label="ODER" />
							</Divider>
							<SubmitButton
								variant="contained"
								color="error"
								fullWidth
								onClick={(e) => {
									setDeleteAccountModal(true);
								}}
							>
								{_t("Nutzeraccount löschen")}
							</SubmitButton>
						</div>
					</GridPaperContainer>
				</Grid>
			</Grid>
		</Layout>
	);
}

export default Profile;
