import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Person from "@mui/icons-material/Person";
import { TextField, Paper, Grid, Typography } from "@mui/material";

import { AvatarStyled, FormStyled, GridImageContainer, GridPaperContainer, SubmitButton } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";

import { updateProfile } from "./accountProfileController.js";
import { _t } from "../../store/translate.js";

function Profile(props) {
	const dispatch = useDispatch();

	const [userProfile, setUserProfile] = React.useState({
		firstname: "",
		lastname: "",
		company: "",
		street: "",
		zip: "",
		city: "",
		country: "",
		phone: "",
	});
	const [savedUserProfile, setSavedUserProfile] = useState({
		firstname: "",
		lastname: "",
		company: "",
		street: "",
		zip: "",
		city: "",
		country: "",
		phone: "",
	});
	const [dataChanged, setDataChanged] = useState(false);

	const handleOnChange = (event) => {
		// var data = event.target.elements;
		var newUserAddress = {};
		let changed = false;
		Object.entries(userProfile).forEach((entry) => {
			const [key, val] = entry;
			if (key === event.target.id) {
				newUserAddress[key] = event.target.value;
			} else {
				newUserAddress[key] = val;
			}
			if (savedUserProfile[key] !== newUserAddress[key]) {
				changed = true;
			}
		});
		if (changed && !dataChanged) {
			setDataChanged(true);
		} else if (!changed && dataChanged) {
			setDataChanged(false);
		}
		setUserProfile(newUserAddress);
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(
			updateProfile(props.user.user._id, {
				data: {
					firstname: userProfile.firstname,
					lastname: userProfile.lastname,
					default_address: {
						company: userProfile.company,
						street: userProfile.street,
						zip: userProfile.zip,
						city: userProfile.city,
						country: userProfile.country,
						phone: userProfile.phone,
					},
				},
			})
		).then((response) => {
			if (response.payload) {
				setSavedUserProfile(userProfile);
				setDataChanged(false);
			}
		});
	};

	React.useEffect(() => {
		async function fetchData() {
			if (props.user.user) {
				let profile = {
					firstname: props.user.user.firstname,
					lastname: props.user.user.lastname,
					company: props.user.user.default_address?.company,
					street: props.user.user.default_address?.street,
					zip: props.user.user.default_address?.zip,
					city: props.user.user.default_address?.city,
					country: props.user.user.default_address?.country,
					phone: props.user.user.default_address?.phone,
				};
				setUserProfile(profile);
				setSavedUserProfile(profile);
			}
		}
		fetchData();
	}, []);

	return (
		<Layout>
			<Grid container>
				<Grid item xs={false} sm={4} md={7} component={GridImageContainer} vendor={props.vendor.postfix} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<GridPaperContainer>
						<AvatarStyled>
							<Person />
						</AvatarStyled>
						<Typography component="h1" variant="h5">
							{_t("Profil")}
						</Typography>
						<FormStyled
							noValidate
							onSubmit={(e) => {
								handleSubmit(e);
							}}
							onChange={(e) => {
								handleOnChange(e);
							}}
						>
							<TextField variant="outlined" margin="normal" id="firstname" label={_t("Vorname")} name="firstname" value={userProfile.firstname} required />
							<TextField variant="outlined" margin="normal" id="lastname" label={_t("Name")} lastname="lastname" value={userProfile.lastname} required />
							<TextField variant="outlined" margin="normal" id="company" label={_t("Firma")} name="company" value={userProfile.company} />
							<TextField variant="outlined" margin="normal" id="street" label={_t("Straße")} name="street" value={userProfile.street} />
							<TextField variant="outlined" margin="normal" id="zip" label={_t("Postleitzahl")} name="zip" value={userProfile.zip} />
							<TextField variant="outlined" margin="normal" id="city" label={_t("Stadt")} name="city" value={userProfile.city} />
							<TextField variant="outlined" margin="normal" id="country" label={_t("Land")} name="country" value={userProfile.country} />
							<TextField
								variant="outlined"
								margin="normal"
								id="phone"
								label={_t("Telefon")}
								name="phone"
								type="tel"
								pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
								value={userProfile.phone}
							/>
							<SubmitButton type="submit" fullWidth variant="contained" color="primary" disabled={!dataChanged}>
								{_t("Profil ändern")}
							</SubmitButton>
						</FormStyled>
					</GridPaperContainer>
				</Grid>
			</Grid>
		</Layout>
	);
}

export default Profile;
