import { styled } from "@mui/system";
import Images from "./store/images.js";
import { Avatar, Button } from "@mui/material";

export const GridImageContainer = styled("div")(({ theme, vendor }) => ({
	backgroundImage: `url(${Images(vendor).background})`,
	backgroundRepeat: "no-repeat",
	backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
	backgroundSize: "cover",
	boxShadow: theme.shadows[6],
}));

export const GridPaperContainer = styled("div")(({ theme }) => ({
	margin: theme.spacing(8, 4),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}));

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
}));

export const FormStyled = styled("form")(({ theme }) => ({
	width: "90%", // Fix IE 11 issue.
	marginTop: theme.spacing(1),
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
	margin: theme.spacing(3, 0, 2),
}));

export const GridPadding = {
	padding: "10px 0px 15px",
};

export const VendorStylesContainer = styled("div")(({ theme, vendor }) => ({
	"& .MuiButton-containedPrimary:not(.Mui-disabled)": {
		backgroundColor: Images(vendor).primaryButtonColor || "#3f51b5",
	},
	"& .MuiButtonBase-colorPrimary:not(.Mui-disabled), .MuiSvgIcon-colorPrimary:not(.Mui-disabled)": {
		color: Images(vendor).primaryButtonColor || "#3f51b5",
	},
	"& .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault": {
		backgroundColor: Images(vendor).primaryButtonColor || "#3f51b5",
	},
}));
