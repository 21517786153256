import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
} from "@mui/material";

import { VendorStylesContainer } from "../../../mainStyle.js";

import { _t } from "../../../store/translate.js";

export function ModalDialog(props) {
	const [open, setOpen] = React.useState(props.opened);

	// const handleOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
		if (props.closed) {
			props.closed(false);
		}
		setOpen(false);
	};

	const handleAcceptbutton = () => {
		handleClose();
		props.acceptbutton();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			component={VendorStylesContainer}
			vendor={props.vendor.postfix}
		>
			{props.title && <DialogTitle>{props.title}</DialogTitle>}
			<DialogContent sx={{ width: 400, overflow: "scroll" }}>
				{props.text && <DialogContentText>{props.text}</DialogContentText>}
				{props.children}
			</DialogContent>
			<DialogActions>
				{props.acceptbutton && (
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={handleAcceptbutton}
							>
								{props.primaryButtonTitle
									? _t(props.primaryButtonTitle)
									: _t("OK")}
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="inherit" onClick={handleClose}>
								{props.cancelButtonTitle
									? _t(props.cancelButtonTitle)
									: _t("Abbrechen")}
							</Button>
						</Grid>
					</Grid>
				)}
				{!props.acceptbutton && !props.navigateTo && (
					<Grid container justifyContent="flex-end" spacing={1}>
						<Button variant="contained" color="primary" onClick={handleClose}>
							{_t("OK")}
						</Button>
					</Grid>
				)}
				{props.navigateTo && (
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={handleClose}
								href={props.navigateTo}
								target="_blank"
								rel="noopener noreferrer"
							>
								{_t("Anzeigen")}
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="inherit" onClick={handleClose}>
								{_t("Abbrechen")}
							</Button>
						</Grid>
					</Grid>
				)}
			</DialogActions>
		</Dialog>
	);
}
