import { _t } from "./translate";

/* select Input */
export function selectInput(event) {
	if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
		event.target.select();
	}
}

export function sortObjByItem(array, item, asc = false, isString = false) {
	var order = asc ? 1 : -1;
	if (isString) {
		return array.sort((a, b) => (a[item] > b[item] ? order : -order));
	} else {
		return array.sort((a, b) => (parseInt(a[item]) > parseInt(b[item]) ? order : -order));
	}
}

export function reorderLevel(objArray, asc = false) {
	var level = 1;
	if (asc) {
		for (let i = 0; i < objArray.length; i++) {
			objArray[i].level = level++;
		}
		return objArray;
	} else {
		for (let i = objArray.length - 1; i >= 0; i--) {
			objArray[i].level = level++;
		}
		return objArray;
	}
}

export function timeout(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getValidSystemGroups(groups) {
	if (!groups) {
		return null;
	}
	return Object.entries(groups)
		.filter(
			([groupName, groupData]) =>
				!!groupData["SystemSets"] &&
				Object.entries(groupData["SystemSets"]).some(
					([name, set]) => String.fromCharCode(set.typ) <= "C" && set.einsatzFlags && (set.einsatzFlags & 0x2000) === 0x2000 && (set.einsatzFlags & 0x0001) === 0x0001
				)
		)
		.reduce((a, e) => {
			a[e[0]] = e[1];
			return a;
		}, {});
}

export function getBuildingTypes(vendor) {
	switch (vendor) {
		case "buderus": {
			return [
				{ name: _t("Benutzerdefiniert"), power_per_m2: 70, edit: true },
				{ name: _t("Bestand 1977"), power_per_m2: 160 },
				{ name: _t("WSchV 77"), power_per_m2: 100 },
				{ name: _t("WSchV 1982"), power_per_m2: 80 },
				{ name: _t("WSchV 1995"), power_per_m2: 50 },
				{ name: _t("EnEV 2002"), power_per_m2: 40 },
				{ name: _t("Niedrigenergiehaus"), power_per_m2: 32 },
				{ name: _t("Ultra-Niedrig-Energiehaus / Drei-Liter-Haus"), power_per_m2: 22 },
				{ name: _t("KFW 40"), power_per_m2: 40 },
				{ name: _t("KFW 55"), power_per_m2: 55 },
			];
		}
		case "cosmo":
		case "purmo":
		case "v&n": {
			return [
				{ name: _t("Benutzerdefiniert"), power_per_m2: 70, edit: true },
				{ name: _t("Bestand < 1995"), power_per_m2: 100 },
				{ name: _t("WSVO 1995"), power_per_m2: 80 },
				{ name: _t("EnEV 2002"), power_per_m2: 50 },
				{ name: _t("EnEV 2014"), power_per_m2: 40 },
				{ name: _t("GEG 2020"), power_per_m2: 30 },
				{ name: _t("KfW 55"), power_per_m2: 25 },
			];
		}
		default: {
			return [
				{ name: _t("Benutzerdefiniert"), power_per_m2: 70, edit: true },
				{ name: _t("Bestand 1977"), power_per_m2: 160 },
				{ name: _t("WSchV 77"), power_per_m2: 100 },
				{ name: _t("WSchV 1982"), power_per_m2: 80 },
				{ name: _t("WSchV 1995"), power_per_m2: 50 },
				{ name: _t("EnEV 2002"), power_per_m2: 40 },
				{ name: _t("Niedrigenergiehaus"), power_per_m2: 32 },
				{ name: _t("Ultra-Niedrig-Energiehaus / Drei-Liter-Haus"), power_per_m2: 22 },
			];
		}
	}
}
