import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Link, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Menu, Alert, AlertTitle } from "@mui/material";
import Cookies from "js-cookie";

import { logoutUser } from "../../store/auth.js";
import { changeVendorLanguage } from "./headerController.js";
import { _t } from "../../store/translate.js";
import { Person } from "@mui/icons-material";
import Images from "../../store/images.js";

function Header(props) {
	const user = props.user ? props.user.user : undefined;
	const hasJson = props && props.user && props.user.jsonDataId ? true : false;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [openAlert, setOpenAlert] = useState(true);

	/* UserLanguage */
	const languages = props.vendor.languages ? props.vendor.languages : ["de"];
	const [currentLanguage, setCurrentLanguage] = React.useState(Cookies.get("language"));
	const [anchorEl_language, setAnchorEl_language] = React.useState(null);
	const handleClick_language = (event) => {
		setAnchorEl_language(event.currentTarget);
	};
	const handleClose_language = async (lang) => {
		if (lang) {
			dispatch(changeVendorLanguage(lang)).then((response) => {
				setCurrentLanguage(lang);
				navigate("/projectList");
				window.location.reload();
			});
		} else {
			setAnchorEl_language(null);
		}
	};
	const ChangeLanguageComponent = (
		<Grid item>
			<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick_language} color="inherit">
				{currentLanguage}
			</Button>
			{languages && languages.length > 1 && (
				<Menu
					id="simple-menu"
					anchorEl={anchorEl_language}
					keepMounted
					open={Boolean(anchorEl_language)}
					onClose={(event) => {
						handleClose_language(null);
					}}
				>
					{languages.map((lang) => (
						<MenuItem
							key={lang}
							onClick={(event) => {
								handleClose_language(lang);
							}}
							style={{ textTransform: "uppercase" }}
						>
							{lang}
						</MenuItem>
					))}
				</Menu>
			)}
		</Grid>
	);

	/* UserButton */
	const [openUserButton, setOpenUserButton] = React.useState(false);
	const anchorRefUserButton = React.useRef(null);
	const handleToggle_UserButton = () => {
		setOpenUserButton((prevOpen) => !prevOpen);
	};
	const handleClose_UserButton = (event) => {
		if (anchorRefUserButton.current && anchorRefUserButton.current.contains(event.target)) {
			return;
		}

		setOpenUserButton(false);
	};
	function handleListKeyDown_UserButton(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenUserButton(false);
		}
	}
	const handleLogout = (event) => {
		handleClose_UserButton(event);
		dispatch(logoutUser()).then(() => {
			if (props.vendor.postfix === "kermi") {
				window.location = "https://kermi.de";
			} else {
				navigate("/");
			}
		});
	};
	const goToProfile = () => {
		navigate("/profile");
	};
	const goToMyAccount = () => {
		navigate("/account");
	};

	const prevOpen = React.useRef(openUserButton);
	React.useEffect(() => {
		if (prevOpen.current === true && openUserButton === false) {
			anchorRefUserButton.current.focus();
		}

		prevOpen.current = openUserButton;
	}, [openUserButton]);

	return (
		<>
			{props.vendor.postfix === "viega" && (
				<>
					<link rel="preconnect" href="//app.usercentrics.eu" />
					<link rel="preconnect" href="//api.usercentrics.eu" />
					<link rel="preconnect" href="//privacy-proxy.usercentrics.eu" />
					<link rel="preload" href="//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" as="script" />
					<script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>
					<script>uc.deactivateBlocking(["f6nkjdUL"]);</script>
					<script src="https://assets.adobedtm.com/4bd80a146c61/6d524a6a684d/launch-f7dfda337657.min.js" async></script>
				</>
			)}
			<Grid
				item
				container
				xs={12}
				component="nav"
				sx={{ position: "fixed", zIndex: 5, height: "69px", background: "#fafafa", padding: "20px" }}
				justifyContent="space-around"
			>
				<Link href={props.vendor.postfix === "kermi" ? "https://kermi.de" : "/"}>
					<Grid
						item
						sx={{
							position: "absolute",
							backgroundImage: `url(${Images(props.vendor.postfix).logo_large})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "contain",
							width: "100%",
							height: "100%",
							maxWidth: "300px",
						}}
					/>
				</Link>
				{props.vendor.postfix === "viega" && openAlert && (
					<Alert
						severity="warning"
						onClose={() => {
							setOpenAlert(false);
						}}
						style={{ position: "absolute", top: 0, left: 100, zIndex: 1 }}
					>
						<AlertTitle>
							<b>Wichtiger Hinweis</b>
						</AlertTitle>
						Die <b>Fonterra Schnellauslegung</b> wird zum <b>25.04.2024</b> abgeschaltet. Nutzen Sie zukünftig bitte den{" "}
						<b>
							<Link href="https://www.viega.de/de/service/software-tools/viptool-assistant.html" target="_blank">
								Viega Viptool Assistant
							</Link>
						</b>
						. Sichern Sie bitte die Ergebnisse Ihrer Projekte auf Ihrem lokalen Rechner.
					</Alert>
				)}
				{user ? (
					<Grid container item xs={12} direction="row" justifyContent="flex-end" spacing={2}>
						{ChangeLanguageComponent}
						{hasJson && (
							<Grid item>
								<Button href="/projectlist" color="inherit">
									{_t("Projekte")}
								</Button>
							</Grid>
						)}
						{user.role === "linear_admin" && (
							<Grid item>
								<Button href="/administration" color="inherit">
									{_t("Administration")}
								</Button>
							</Grid>
						)}
						{(user.role === "linear_admin" || user.role === "vendor_admin") && (
							<Grid item>
								<Button href="https://linear-flaechenheizung-dash.herokuapp.com/" target="_blank" color="inherit">
									{_t("Dashboard")}
								</Button>
							</Grid>
						)}

						<Grid item>
							<Button
								color="inherit"
								ref={anchorRefUserButton}
								aria-controls={openUserButton ? "menu-list-grow" : undefined}
								aria-haspopup="true"
								onClick={handleToggle_UserButton}
							>
								{!user.firstname && !user.lastname && <Person fontSize="small" />}
								{user.firstname} {user.lastname}
							</Button>
							<Popper open={openUserButton} anchorEl={anchorRefUserButton.current} role={undefined} transition disablePortal>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === "bottom" ? "center top" : "center bottom",
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleClose_UserButton}>
												<MenuList autoFocusItem={openUserButton} id="menu-list-grow" onKeyDown={handleListKeyDown_UserButton}>
													<MenuItem onClick={goToProfile}>{_t("Profil")}</MenuItem>
													{props.vendor.postfix !== "kermi" && <MenuItem onClick={goToMyAccount}>{_t("Mein Account")}</MenuItem>}
													<MenuItem onClick={handleLogout}>{_t("Abmelden")}</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Grid>
					</Grid>
				) : (
					<Grid container item xs={12} direction="row" justifyContent="flex-end" spacing={2}>
						{ChangeLanguageComponent}
						{/* <Grid item>
						<Button href="/">{_t("Anmelden")}</Button>
					</Grid>
					<Grid item>
						<Button href="/register">{_t("Registrieren")}</Button>
					</Grid> */}
					</Grid>
				)}
			</Grid>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		vendor: state.vendor,
		user: state.user,
	};
};

export default connect(mapStateToProps)(Header);
