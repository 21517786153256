import React, { useCallback } from "react";

import {
	Button,
	Grid,
	Typography,
	CircularProgress,
	Paper,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	Table,
	TableBody,
	TextField,
	FormControlLabel,
	Checkbox,
	Box,
} from "@mui/material";
import { debounce } from "lodash";

import { MaterialStyle, TotalCostStyle } from "./materialStyle.js";
import { GridPadding } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import Images from "../../store/images.js";

import { getCalculatedMaterials } from "../project/projectController.js";
import { selectInput } from "../../store/gloablFunctions.js";
import { editMaterial, downloadFile, getPdf } from "./materialController.js";
import { Format, _t } from "../../store/translate.js";
import { useParams } from "react-router-dom";

function Material(props) {
	const logo = Images(props.vendor.postfix)?.logo;
	const { id } = useParams();

	const vendor = props?.vendor?.postfix || "";
	const hasPrices = props?.vendor?.preferences?.has_prices;
	const hasCheckBox = props?.vendor?.preferences?.minimum_packaging_quantity;

	/* Buttons */
	const [isPackagingQuantity, setIsPackagingQuantity] = React.useState(false);
	const handleCheck_changePackagingQuantity = async (checked) => {
		setLoading(true);
		var request = await getCalculatedMaterials(id, checked);
		if (request) {
			calcSumOfMaterials(request);
			setCalculatedMaterials(request);
			setIsPackagingQuantity(checked);
		}
		setLoading(false);
	};

	/* Kalkulationen */
	const materialSetNames = {
		system: _t("Systeme/Systemzubehör"),
		control_system: _t("Einzelraumregelung"),
		distributor: _t("Verteiler, Schränke und Zubehör"),
		screeds: _t("Estrichzusatzmittel"),
	};
	const [calculatedMaterials, setCalculatedMaterials] = React.useState({});
	const getStateCalculatedMaterials = () => {
		var newcalculatedMaterials = {};
		Object.entries(calculatedMaterials).forEach((entry) => {
			const [key, obj] = entry;
			newcalculatedMaterials[key] = obj;
		});
		return newcalculatedMaterials;
	};
	const handleChange_quantity = async (key, item, value) => {
		if (value !== "" && value >= 0) {
			var newcalculatedMaterials = getStateCalculatedMaterials();
			var foundMaterial = newcalculatedMaterials[key].find((m) => m._id === item._id);
			value = Math.ceil(value);
			foundMaterial.quantity = value;
			var oldPrice = item.price;
			foundMaterial.price = foundMaterial.unit_price * value;
			setCalculatedMaterials(newcalculatedMaterials);
			if (item.selected) {
				var addToSum = foundMaterial.price - oldPrice;
				addToSumOfMaterials(key, addToSum);
			}
			debounce_Material(foundMaterial);
		}
	};
	const debounce_Material = useCallback(
		debounce(async (foundMaterial) => {
			await editMaterial(foundMaterial._id, foundMaterial);
		}, 1000),
		[]
	);
	const handleChange_selected = async (key, item, checked) => {
		var newcalculatedMaterials = getStateCalculatedMaterials();
		var foundMaterial = newcalculatedMaterials[key].find((m) => m._id === item._id);
		foundMaterial.selected = checked;
		await editMaterial(item._id, foundMaterial);

		var addToSum = checked ? item.price : -item.price;
		addToSumOfMaterials(key, addToSum);

		setCalculatedMaterials(newcalculatedMaterials);
	};

	/* Summen */
	const [sumOfMaterials, setSumOfMaterials] = React.useState({});
	const getStateSumOfMaterials = () => {
		var newSumOfMaterials = {};
		Object.entries(sumOfMaterials).forEach((entry) => {
			const [key, obj] = entry;
			newSumOfMaterials[key] = obj;
		});
		return newSumOfMaterials;
	};
	const addToSumOfMaterials = (key, addToSum) => {
		var newSumOfMaterials = getStateSumOfMaterials();
		newSumOfMaterials[key] += addToSum;
		newSumOfMaterials["total"] += addToSum;
		setSumOfMaterials(newSumOfMaterials);
	};
	const calcSumOfMaterials = (newCalculatedMaterials) => {
		var newSumOfMaterials = {};
		newSumOfMaterials["total"] = 0;
		Object.entries(newCalculatedMaterials).forEach((entry) => {
			const [key, obj] = entry;
			newSumOfMaterials[key] = 0;
			obj.forEach((element) => {
				if (element.selected) {
					newSumOfMaterials[key] += element.price;
				}
			});
			newSumOfMaterials["total"] += newSumOfMaterials[key];
		});
		setSumOfMaterials(newSumOfMaterials);
	};

	const handleImageClick = (url) => {
		window.open(url, "_blank");
	};

	const roundFloat = (num) => {
		return parseFloat(num).toFixed(2);
	};

	const handleClick_getPdf = async (pdfWindow) => {
		var request = await getPdf(id, isPackagingQuantity);
		pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(request) + "'></iframe>");
	};

	const handleClick_getAsd = () => downloadFile(id, isPackagingQuantity, "Asd");
	const handleClick_getUgs = () => downloadFile(id, isPackagingQuantity, "Ugs");
	const handleClick_getCsv = () => downloadFile(id, isPackagingQuantity, "Csv");

	const [loading, setLoading] = React.useState(true);
	React.useEffect(() => {
		async function fetchData() {
			var request = await getCalculatedMaterials(id, isPackagingQuantity);
			var newCalculatedMaterials = request;
			calcSumOfMaterials(newCalculatedMaterials);
			setCalculatedMaterials(newCalculatedMaterials);
			setLoading(false);
		}
		fetchData();
	}, [isPackagingQuantity, props]);

	return (
		<Layout>
			{loading && <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />}
			{!loading && (
				<Grid item xs={12}>
					<Grid container item xs={12} justifyContent="space-between" sx={GridPadding}>
						<Typography component="h5" variant="h5">
							Materialzusammenstellung
						</Typography>
						<Grid container item sm={12} lg={7} justifyContent="flex-end" spacing={2}>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									onClick={(e) => {
										handleClick_getPdf(window.open("about:blank"));
									}}
								>
									<Typography variant="body1" style={{ paddingLeft: "5px" }}>
										{_t("Drucken")}
									</Typography>
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color="primary" onClick={handleClick_getAsd}>
									<Typography variant="body1" style={{ paddingLeft: "5px" }}>
										{Format(_t("%1 exportieren"), ["ASD"])}
									</Typography>
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color="primary" onClick={handleClick_getUgs}>
									<Typography variant="body1" style={{ paddingLeft: "5px" }}>
										{Format(_t("%1 exportieren"), ["UGS"])}
									</Typography>
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color="primary" onClick={handleClick_getCsv}>
									<Typography variant="body1" style={{ paddingLeft: "5px" }}>
										{Format(_t("%1 exportieren"), ["CSV"])}
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{hasCheckBox && (
							<Grid container item sm={12} lg={12} justifyContent="flex-end">
								<FormControlLabel
									justifyContent="flex-end"
									control={
										<Checkbox
											checked={isPackagingQuantity}
											onChange={(event) => {
												handleCheck_changePackagingQuantity(event.target.checked);
											}}
											name="checked_separate_border_zones"
											color="primary"
										/>
									}
									label={_t("Mindestverpackungsmenge berücksichtigen")}
								/>
							</Grid>
						)}
					</Grid>
					{calculatedMaterials &&
						Object.keys(calculatedMaterials).map((key) => {
							return (
								<Grid key={key} component={MaterialStyle}>
									<Typography component="h6" variant="h6">
										{materialSetNames[key]}
									</Typography>
									<TableContainer component={Paper} sx={{ margin: "20px 0" }}>
										<Table aria-label="customized table">
											<colgroup>
												<col style={{ width: "10%" }} />
												<col style={{ width: "5%" }} />
												<col style={{ width: "30%" }} />
												<col style={{ width: "10%" }} />
												<col style={{ width: "10%" }} />
												<col style={{ width: "10%" }} />
												{hasPrices && <col style={{ width: "10%" }} />}
												{hasPrices && <col style={{ width: "15%" }} />}
											</colgroup>
											<TableHead>
												<TableRow>
													<TableCell align="center">{_t("Optional")}</TableCell>
													<TableCell align="center">{_t("Bild")}</TableCell>
													<TableCell align="center">{_t("Beschreibung")}</TableCell>
													<TableCell align="center">{_t("Artikel-Nr.")}</TableCell>
													<TableCell align="center">{_t("Menge")}</TableCell>
													<TableCell align="center">{_t("Einheit")}</TableCell>
													{hasPrices && <TableCell align="center">{_t("Einzelpreis")}</TableCell>}
													{hasPrices && <TableCell align="center">{_t("Gesamtkosten")}</TableCell>}
												</TableRow>
											</TableHead>
											<TableBody>
												{calculatedMaterials[key] &&
													calculatedMaterials[key].map((item) => (
														<TableRow key={item._id}>
															<TableCell component="th" scope="row" align="center">
																{item.alternate && (
																	<FormControlLabel
																		control={
																			<Checkbox
																				checked={item.selected}
																				onChange={(event) => {
																					handleChange_selected(key, item, event.target.checked);
																				}}
																				name="checkedMaterial"
																				color="primary"
																			/>
																		}
																	/>
																)}
															</TableCell>
															<TableCell component="th" scope="row" align="left">
																<Box
																	component={"img"}
																	src={"https://assets.linear.eu/" + vendor + "/images/" + item.image}
																	style={{ cursor: "pointer" }}
																	sx={{ width: "100%", height: "100%", objectFit: "contain" }}
																	onClick={() => {
																		if (item.image) {
																			handleImageClick("https://assets.linear.eu/" + vendor + "/images/" + item.image);
																		}
																	}}
																	onError={(img) => (img.currentTarget.src = logo)}
																	/**
																	 * Use onError to set the img to the vendors logo
																	 * You dont have to check WHY there is an error e.g. empty string, null, undefined
																	 */
																/>
															</TableCell>
															<TableCell component="th" scope="row" align="center" style={{ whiteSpace: "pre-line" }}>
																{item.description}
															</TableCell>
															<TableCell component="th" scope="row" align="center">
																{item.article_number}
															</TableCell>
															<TableCell component="th" scope="row" align="center">
																<TextField
																	type="number"
																	variant="outlined"
																	value={Math.ceil(item.quantity)}
																	inputProps={{ min: 0, style: { width: "80px", textAlign: "center" } }}
																	disabled={isPackagingQuantity}
																	onClick={selectInput}
																	onWheel={(event) => {
																		event.target.blur();
																	}}
																	onChange={(event) => {
																		handleChange_quantity(key, item, event.target.value);
																	}}
																/>
															</TableCell>
															<TableCell component="th" scope="row" align="center">
																{isPackagingQuantity ? `x${item.packaging_quantity} ${item.unit}` : item.unit}
															</TableCell>
															{hasPrices && (
																<TableCell component="th" scope="row" align="center">
																	{roundFloat(item.unit_price)} {item.currency}
																</TableCell>
															)}
															{hasPrices && (
																<TableCell component="th" scope="row" align="center">
																	{item.selected ? roundFloat(item.price) : roundFloat(0)} {item.currency}
																</TableCell>
															)}
														</TableRow>
													))}
												{hasPrices && (
													<TableRow>
														<TableCell colSpan={7} />
														{sumOfMaterials && (
															<TableCell align="center">
																<Typography component="h5" variant="h5">
																	{_t("Summe:")} {Math.round(sumOfMaterials[key])} {calculatedMaterials[key][0].currency}
																</Typography>
															</TableCell>
														)}
													</TableRow>
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							);
						})}
					{hasPrices && (
						<Grid container justifyContent="flex-end" sx={TotalCostStyle}>
							<Typography component="h5" variant="h5" paragraph>
								{_t("Gesamtkosten:")}
							</Typography>
							<Typography component="h5" variant="h5" paragraph>
								{Math.round(sumOfMaterials["total"])} {calculatedMaterials["system"][0].currency}
							</Typography>
							{/* <TextField value={sumOfMaterials["total"] + " " + calculatedMaterials["system"][0].currency} /> */}
						</Grid>
					)}
				</Grid>
			)}
		</Layout>
	);
}

export default Material;
