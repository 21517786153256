//SERVER ROUTES
export const USER_SERVER = "api/user";
export const VENDOR_SERVER = "api/vendor";
export const JSON_SERVER = "api/json";
export const PROJECT_SERVER = "api/project";
export const PIPE_SERVER = "api/pipeMargin";
export const FLOOR_SERVER = "api/floor";
export const FLAT_SERVER = "api/flat";
export const ROOM_SERVER = "api/room";
export const INSULATION_OPTION_SERVER = "api/insulationOption";
export const MATERIAL_SERVER = "api/material";
export const JSONDATA_SERVER = "api/json";
export const SSO_SERVER = "api/sso";
