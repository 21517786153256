import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import Reducer from "./_reducers";
import promiseMiddleware from "redux-promise";
import { thunk } from "redux-thunk";

const persistConfig = {
	key: "root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, Reducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => [
		thunk,
		promiseMiddleware,
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
	],
});

export default store;
