import axios from "axios";
import { AUTH_VENDOR, LOGOUT_USER, UPLOAD_JSONDATA } from "./types";
import { JSONDATA_SERVER, SSO_SERVER, USER_SERVER, VENDOR_SERVER } from "../config.js";

// export function auth() {
// 	const request = axios({
// 		method: "get",
// 		url: `${USER_SERVER}/fetch`,
// 		baseURL: "/",
// 	})
// 		.then((response) => response?.data)
// 		.catch((err) => err);

// 	return {
// 		type: AUTH_USER,
// 		payload: request,
// 	};
// }

export function vendorAuth(secret) {
	if (!secret) {
		secret = window.location.hostname;
	}
	const request = axios({
		method: "get",
		url: `${VENDOR_SERVER}/getBySecretOrHost/${secret}`,
		baseURL: "/",
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: AUTH_VENDOR,
		payload: request,
	};
}

export function ssoAuth(token) {
	const request = axios({
		method: "get",
		url: `${SSO_SERVER}/verify/${token}`,
		baseURL: "/",
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: AUTH_VENDOR,
		payload: request,
	};
}

export function getCurrentJSONData() {
	const request = axios({
		method: "get",
		url: `${JSONDATA_SERVER}/getCurrent/`,
		baseURL: "/",
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: UPLOAD_JSONDATA,
		payload: request,
	};
}

export function logoutUser() {
	const request = axios({
		method: "get",
		url: `${USER_SERVER}/logout`,
		baseURL: "/",
	}).then((response) => {
		return response?.data;
	});

	return {
		type: LOGOUT_USER,
		payload: request,
	};
}
