import React from "react";
// import { debounce } from "lodash";

import {
	Button,
	Grid,
	Typography,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	TextField,
	MenuItem,
	CircularProgress,
	Tooltip,
	Menu,
	Divider,
} from "@mui/material";
import { Delete, DragIndicator, FileCopy, MoreVert, Send } from "@mui/icons-material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { TableStyles, Wrapper } from "./projectStyle.js";
import { GridPadding } from "../../mainStyle.js";

import { getRooms, editRoom, duplicateRoom, deleteRoom, fetchInsulationOptions, editManyRooms } from "./projectController.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import { reorderLevel, selectInput, sortObjByItem } from "../../store/gloablFunctions.js";
import { Format, _t } from "../../store/translate.js";

/* Inner Roomfunctions*/
const INVALID_INPUT = { error: true, text: _t("Ungültige Eingabe") };
// function addErrorForEmptyInput(room) {
// 	Object.entries(room).forEach((entry) => {
// 		const [key, obj] = entry;
// 		if (obj !== 0 && !obj) {
// 			room[key] = "";
// 			room.errors[key] = INVALID_INPUT;
// 		}
// 	});
// }
function getErrorForAreas(foundRoom) {
	/* border_area dependencies */
	if (
		parseFloat(foundRoom["blind_area"]) + parseFloat(foundRoom["border_area"]) > foundRoom["total_area"] &&
		(parseFloat(foundRoom["blind_area"]) > 0 || parseFloat(foundRoom["border_area"]) > 0)
	) {
		foundRoom.errors["total_area"] = { error: true, text: _t("die Gesamtfläche muss größer sein als Randbereich und Blindbereich.") };
	} else {
		foundRoom.errors["total_area"] = null;
	}
}

/* Outer Roomfunctions*/
export function getErrorForDesiredPower(roomCalculation) {
	return { error: true, text: roomCalculation.error.title, title: roomCalculation.error.description };
}
export async function getInsulationOptions(caseFilter, newProject) {
	let filter = Object.assign(
		{
			system_group: newProject.heat_system.group,
			system_name: newProject.heat_system.name,
			pipe: newProject.heat_system.pipe,
			vendor: newProject.vendor,
			language: newProject.language,
			json_data: newProject.json_data._id,
		},
		caseFilter
	);
	var insulation_options = await fetchInsulationOptions(filter);
	return insulation_options;
}
export async function getRoomsWithExtras(flatId, newProject) {
	var rooms = await getRooms(flatId);
	if (!rooms || !rooms.length) {
		return [];
	}
	var insulation_options = await getInsulationOptions({}, newProject);
	rooms.forEach((room) => {
		room.errors = {};
		getErrorForAreas(room);
		// addErrorForEmptyInput(room);

		room.insulation_options = insulation_options.filter((io) => io.insulation_case === room.insulation_case);
		var foundInsulationOption = insulation_options[room.insulation_option_index];
		if (!foundInsulationOption) {
			room.errors["insulation_option_index"] = INVALID_INPUT;
		}

		// room calculation
		if (room?.calculations?.error?.title) {
			room.errors["desired_power"] = getErrorForDesiredPower(room.calculations);
		}

		// Menu Extras
		room.menu_anchorEl = null;
	});
	return rooms;
}

export function Rooms(props) {
	const {
		project,
		getStateFloors,
		floor,
		setFloors,
		flat,
		findFlat,
		findRoom,
		roomAreaMargins,
		roomThermostates,
		deleteArrayElementById,
		setLightLoading,
		changeCalculatedMaterialsState,
		getItemStyle,
		onDragEnd,
	} = props;

	const [debouncing, setDebouncing] = React.useState([]);
	const DEFAULT_INSULATION_CASES = [
		{ key: "same", value: "g", name: _t("Gleichartige Nutzung") },
		{ key: "not_same", value: "n", name: _t("Nicht gleichartige Nutzung") },
		{ key: "unheated", value: "u", name: _t("Unbeheizte Räume") },
		{ key: "outer", value: "a", name: _t("Gegen Außenluft") },
		{ key: "floor", value: "e", name: _t("Gegen Erdreich") },
	];
	const FLOOR_SURFACES = [
		{ key: "din", value: 0.1, name: _t("DIN Standardwert") },
		{ key: "tiledThick", value: 0.03, name: _t("Fliese (dick)") },
		{ key: "tiledThin", value: 0.02, name: _t("Fliese (dünn)") },
		{ key: "marble", value: 0.04, name: _t("Naturstein/Marmor") },
		{ key: "pvc", value: 0.05, name: _t("PVC 4mm") },
		{ key: "linoleum", value: 0.05, name: _t("Linoleum 4mm") },
		{ key: "parquetThin", value: 0.06, name: _t("Parkett 8mm") },
		{ key: "parquetThick", value: 0.11, name: _t("Parkett 16mm") },
		{ key: "wooden", value: 0.08, name: _t("Kork") },
		{ key: "carpet", value: 0.08, name: _t("Teppich 5mm") },
		{ key: "tileAndCarpet", value: 0.06, name: _t("Fliese + 50% Teppich") },
		{ key: "max", value: 0.15, name: _t("Maximaler Rwert") },
	];
	const isInvalidNumber = (attribute, value) => {
		return (
			((attribute === "count" ||
				attribute === "length" ||
				attribute === "width" ||
				attribute === "total_area" ||
				attribute === "border_area" ||
				attribute === "desired_power" ||
				attribute === "connection_length") &&
				value < 0) ||
			value === "" ||
			(attribute === "count" && (value.indexOf(".") !== -1 || value.indexOf(",") !== -1))
		);
	};

	const getCircuitText = (room) => {
		var circuitsText = "-";
		if (room.calculations.num_of_main_zone_circuits + room.calculations.num_of_border_zone_circuits > 0) {
			if (room.calculations.main_zone_circuit && room.calculations.main_zone_circuit.area > 0) {
				circuitsText = room.calculations.num_of_main_zone_circuits;
				var main_area_margin_index = room.calculations.main_zone_circuit.pipe_margin_index + 1; //room.fixed_main_area_margin == -1 ? project.heat_system.pipe_margin_min_index + 1 : room.fixed_main_area_margin + 1;
				circuitsText += "(" + (roomAreaMargins[main_area_margin_index].margin / 10).toFixed(1) + ")";
			} else {
				circuitsText = "0+";
			}
			if (room.calculations.num_of_border_zone_circuits > 0) {
				circuitsText += room.calculations.num_of_border_zone_circuits;
				var border_area_margin_index = room.calculations.border_zone_circuit.pipe_margin_index + 1; //room.fixed_border_area_margin == -1 ? project.heat_system.pipe_margin_max_index + 1 : room.fixed_border_area_margin + 1;
				circuitsText += "(" + (roomAreaMargins[border_area_margin_index].margin / 10).toFixed(1) + ")";
			}
		}
		return circuitsText;
	};
	const getCircuitTooltip = (room) => {
		var circuitsTooltip = "";
		if (room.calculations.num_of_main_zone_circuits + room.calculations.num_of_border_zone_circuits > 0) {
			circuitsTooltip += _t("Anzahl Aufenthaltszonen (Verlegeabstand)");
			if (room.calculations.num_of_border_zone_circuits > 0) {
				circuitsTooltip += " + " + _t("Anzahl Randzonen (Verlegeabstand)");
			}
		}
		return circuitsTooltip;
	};
	const fixEmptyInputToZero = (room) => {
		if (room.errors) {
			Object.entries(room.errors).forEach((entry) => {
				const [key] = entry;
				if (room[key] === "") {
					room.errors[key] = null;
					room[key] = 0;
				}
			});
		}
		return room;
	};
	const getAdjacent_temperature = (value) => {
		if (value === "g") {
			return "20";
		} else if (value === "n") {
			return "15";
		} else if (value === "u") {
			return "6";
		} else if (value === "a") {
			return "-14";
		} else if (value === "e") {
			return "9";
		}
	};
	const get_leistung_per_m2 = (temperature, newProject = project) => {
		if (temperature > 20) {
			var additional_percent = (temperature - 20) * 7;
			return Math.round((newProject.power_per_m2 * (additional_percent + 100)) / 100);
		} else {
			return ((temperature + 14) / (20 + 14)) * newProject.power_per_m2;
		}
	};
	const getDesiredPower = (total_area, temperature, newProject = project) => {
		var desired_power = parseInt(total_area) * get_leistung_per_m2(parseInt(temperature), newProject);
		return Math.round(desired_power);
	};
	const getStateDebouncing = () => {
		var newDebouncing = [];
		debouncing.forEach((d) => {
			newDebouncing.push(d);
		});
		return newDebouncing;
	};
	const calcRoom = async (attribute, foundRoom) => {
		foundRoom = fixEmptyInputToZero(foundRoom);
		var changedTotalArea = attribute === "total_area";

		/* adjust dependencie values */
		if (attribute === "length" || attribute === "width") {
			foundRoom["total_area"] = parseFloat((foundRoom.length * foundRoom.width).toFixed(2));
			changedTotalArea = true;
		} else if (changedTotalArea) {
			foundRoom["length"] = 0;
			foundRoom["width"] = 0;
		}
		/* desired_power dependencies */
		if (changedTotalArea || attribute === "temperature" || foundRoom.desired_power <= 0) {
			foundRoom.desired_power = getDesiredPower(foundRoom.total_area, foundRoom.temperature, project);
		}

		/* inculation_case dependencies */
		if (attribute === "insulation_case") {
			let value = foundRoom[attribute];
			var insulation_options = await getInsulationOptions({ insulation_case: value }, project);
			foundRoom["insulation_options"] = insulation_options;
			foundRoom["insulation_option_index"] = 0;
			foundRoom["adjacent_temperature"] = getAdjacent_temperature(value);
		}
	};
	const debounce_room = (attribute, room) => {
		var value = room[attribute];
		var newDebouncing = getStateDebouncing();
		var currentDebouncing = newDebouncing.find((x) => x.id === room._id);
		if (currentDebouncing?.typingTimeout) {
			clearTimeout(currentDebouncing.typingTimeout);
			newDebouncing.filter(function (x) {
				return x.id !== room._id;
			});
		}
		newDebouncing.push({
			id: room._id,
			value: value,
			typing: false,
			typingTimeout: setTimeout(function () {
				callback_room(attribute, room);
			}, 1000),
		});
		setDebouncing(newDebouncing);
	};
	const callback_room = async (attribute, room) => {
		var newFloors = getStateFloors();
		var foundRoom = findRoom(newFloors, room.floor, room.flat, room._id);
		//remove menu_anchorEl for server
		var menu_anchorEl = foundRoom.menu_anchorEl;
		foundRoom.menu_anchorEl = null;
		if (attribute === "desired_power") {
			room.user_input = true;
		}
		if (["length", "width", "total_area", "border_area", "blind_area", "temperature"].includes(attribute)) {
			room.user_input = false;
		}

		var value = room[attribute];
		if (!isInvalidNumber(attribute, value)) {
			var editedRoom = await editRoom(foundRoom._id, foundRoom);
			//add missing attributes for client room
			editedRoom.menu_anchorEl = menu_anchorEl;
			editedRoom.insulation_options = foundRoom.insulation_options;
			editedRoom.floor = foundRoom.floor;
			editedRoom.flat = foundRoom.flat;
			editedRoom.project = foundRoom.project;
			editedRoom.errors = foundRoom.errors;

			getErrorForAreas(editedRoom);

			/* desired_power errors refresh */
			if (editedRoom.calculations && editedRoom.calculations.error && editedRoom.calculations.error.title) {
				editedRoom.errors["desired_power"] = getErrorForDesiredPower(editedRoom.calculations);
			} else {
				editedRoom.errors["desired_power"] = null;
			}

			changeCalculatedMaterialsState();

			//set editedRoom, is needed to set changes for newFloors Object
			var foundFlat = findFlat(newFloors, room.floor, room.flat);
			var rooms = foundFlat.rooms;
			var indexOfRoom = rooms.indexOf(foundRoom);
			rooms[indexOfRoom] = editedRoom;
			foundRoom = findRoom(newFloors, room.floor, room.flat, room._id);
		}

		foundRoom.loading = false;
		setFloors(newFloors);
	};
	// const debounce_room = useCallback(debounce(editedRoomS, 1000), []);

	/* Events */
	const handleClick_DuplicateRoom = async (floorId, flatId, room) => {
		setLightLoading(true);
		var request = await duplicateRoom(room._id, flatId, room.level);
		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);

		var insulation_options = await getInsulationOptions({ insulation_case: request.insulation_case }, project);
		request.insulation_options = insulation_options;

		request.errors = {};
		if (request.calculations && request.calculations.error && request.calculations.error.title) {
			request.errors["desired_power"] = getErrorForDesiredPower(request.calculations);
		}

		//closeMenu
		var foundRoom = findRoom(newFloors, floorId, flatId, room._id);
		if (foundRoom) {
			foundRoom.menu_anchorEl = null;
		}

		//load rooms or add to loaded rooms
		if (!foundFlat.rooms) {
			var rooms = await getRoomsWithExtras(flatId, project);
			foundFlat.rooms = rooms;
		} else {
			foundFlat.rooms.push(request);
			foundFlat.rooms = sortObjByItem(foundFlat.rooms, "level", true);
		}
		reorderLevel(foundFlat.rooms, true);
		foundFlat.expanded = true;

		setFloors(newFloors);
		changeCalculatedMaterialsState();
		setLightLoading(false);
	};
	const handleClick_EditRoom = async (attribute, value, floorId, flatId, roomId) => {
		var newFloors = getStateFloors();
		var foundRoom = findRoom(newFloors, floorId, flatId, roomId);
		foundRoom[attribute] = value;

		if (!foundRoom["errors"]) {
			foundRoom["errors"] = {};
		}

		if (!isInvalidNumber(attribute, value)) {
			foundRoom.loading = true;
			foundRoom["errors"][attribute] = null;
			await calcRoom(attribute, foundRoom);
			debounce_room(attribute, foundRoom);
		} else {
			foundRoom["errors"][attribute] = INVALID_INPUT;
		}

		setFloors(newFloors);
		// debounce_room(project, newFloors, attribute, value, floorId, flatId, roomId);
	};
	const handleClick_toggleMenu = (currentTarget, floorId, flatId, roomId) => {
		var newFloors = getStateFloors();
		var foundRoom = findRoom(newFloors, floorId, flatId, roomId);
		foundRoom.menu_anchorEl = currentTarget;
		setFloors(newFloors);
	};
	const handleClick_DeleteRoom = async (floorId, flatId, roomId) => {
		setLightLoading(true);
		await deleteRoom(roomId);
		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);
		foundFlat.rooms = deleteArrayElementById(foundFlat.rooms, roomId);
		reorderLevel(foundFlat.rooms, true);
		setFloors(newFloors);
		changeCalculatedMaterialsState();
		setLightLoading(false);
	};

	/* Send Modal */
	const [sendModal, setSendModal] = React.useState(false);
	const [toSend, setToSend] = React.useState({ name: "", to: "", selections: [], toSelected: "", item: "" });
	const SendModalContent = (
		<Grid container>
			<TextField
				select
				label={toSend.to}
				size="small"
				variant="outlined"
				value={toSend.toSelected}
				onChange={(event) => {
					setToSend({
						name: toSend.name,
						to: toSend.to,
						selections: toSend.selections,
						toSelected: event.target.value,
						item: toSend.item,
					});
				}}
			>
				{toSend.selections.map((selection) => (
					<MenuItem key={selection._id} value={selection._id}>
						{selection.name}
					</MenuItem>
				))}
			</TextField>
		</Grid>
	);
	const handleClick_openSendModal = (name, selections, toSelected, item) => {
		var to = "Wohnung";
		setToSend({ name, to, selections, toSelected, item });
		setSendModal(true);
	};
	const handleClick_sendOnConfirmation = async () => {
		var floorId = toSend.item.floor;
		var flatId = toSend.toSelected;
		var room = toSend.item;
		await handleClick_DuplicateRoom(floorId, flatId, room);
	};

	/* Edit All Insulation_cases Modal*/
	const [editAllInsulationsModal, setEditAllInsulationsModal] = React.useState(false);
	const [toEdit, setToEdit] = React.useState({ insulation_case: "", insulation_option_index: 0, flatId: "", floorId: "" });
	const handleClick_EditAllInsulations = async () => {
		var { insulation_case, insulation_option_index, flatId, floorId } = toEdit;

		var newRooms = await editManyRooms({ flat: flatId }, { insulation_case, insulation_option_index });
		newRooms = sortObjByItem(newRooms, "level", true);

		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);
		var insulation_options = await getInsulationOptions({}, project);
		newRooms.forEach((room) => {
			var foundRoom = foundFlat.rooms.find((r) => r._id === room._id);
			//add missing attributes for client room
			room.menu_anchorEl = foundRoom.menu_anchorEl;
			room.insulation_options = insulation_options.filter((io) => io.insulation_case === room.insulation_case);
			room.floor = foundRoom.floor;
			room.flat = foundRoom.flat;
			room.project = foundRoom.project;
			room.errors = {};
			room.loading = false;

			//add errors
			if (room?.calculations?.error?.title) {
				room.errors["desired_power"] = getErrorForDesiredPower(room.calculations);
			}
		});
		foundFlat.rooms = newRooms;
		setFloors(newFloors);
	};

	/* Edit All Insulation_Options Modal*/
	const [editAllVariantsModal, setEditAllVariantsModal] = React.useState(false);
	const handleClick_EditAllVariants = async () => {
		var { insulation_case, insulation_option_index, flatId, floorId } = toEdit;

		var newRooms = await editManyRooms({ insulation_case, flat: flatId }, { insulation_option_index });

		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);
		var insulation_options = await getInsulationOptions({}, project);
		let nonUpdatedRooms = flat.rooms.filter((r) => !newRooms.find((x) => x._id === r._id));
		let allFlatRooms = sortObjByItem([...nonUpdatedRooms, ...newRooms], "level", true);
		allFlatRooms.forEach((room) => {
			var foundRoom = foundFlat.rooms.find((r) => r._id === room._id);
			//add missing attributes for client room
			room.menu_anchorEl = foundRoom.menu_anchorEl;
			room.insulation_options = insulation_options.filter((io) => io.insulation_case === room.insulation_case);
			room.floor = foundRoom.floor;
			room.flat = foundRoom.flat;
			room.project = foundRoom.project;
			room.errors = {};
			room.loading = false;

			//add errors
			if (room?.calculations?.error?.title) {
				room.errors["desired_power"] = getErrorForDesiredPower(room.calculations);
			}
		});
		foundFlat.rooms = allFlatRooms;
		setFloors(newFloors);
	};

	const selectNextField = (e, nextTarget) => {
		if (e.key === "Enter" || e.key === "ArrowDown") {
			e.preventDefault();
			var nextTr = e.target.closest("tr").nextSibling;
			if (nextTr) {
				var nextLine = nextTr.querySelector(nextTarget);
				if (e.target.tagName === "DIV") {
					// e.stopPropagation();
					// e.nativeEvent.stopImmediatePropagation();
					// e.target.blur();
					nextLine.querySelector(".MuiSelect-selectMenu");
				} else {
					nextLine.querySelector("input").select();
				}
			}
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			var previousTr = e.target.closest("tr").previousSibling;
			if (previousTr) {
				previousTr.querySelector(nextTarget).querySelector("input").select();
			}
		}
		// else if (e.key === "ArrowRight") {
		// 	e.target.closest("th").nextSibling.querySelector("input").focus();
		// } else if (e.key === "ArrowLeft") {
		// 	e.target.closest("th").previousSibling.querySelector("input").focus();
		// }
	};

	return (
		<Grid container justifyContent="space-between" sx={GridPadding}>
			{sendModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={setSendModal}
					title={Format(_t("%1 senden:"), [toSend.name])}
					acceptbutton={handleClick_sendOnConfirmation}
					children={SendModalContent}
				/>
			)}
			{editAllInsulationsModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={setEditAllInsulationsModal}
					title={_t("Dämmung für alle Räume dieser Wohnung anpassen?")}
					primaryButtonTitle={_t("Ja")}
					cancelButtonTitle={_t("Nein")}
					acceptbutton={handleClick_EditAllInsulations}
				/>
			)}
			{editAllVariantsModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={setEditAllVariantsModal}
					title={_t("Varianten für alle Räume dieser Wohnung anpassen?")}
					primaryButtonTitle={_t("Ja")}
					cancelButtonTitle={_t("Nein")}
					acceptbutton={handleClick_EditAllVariants}
				/>
			)}
			<TableContainer component={TableStyles}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							{/* <TableCell align="center"></TableCell> */}
							<TableCell align="center">
								<Tooltip title={_t("Raumanzahl")}>
									<div>{_t("Multiplikator")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Raumname")}>
									<div>{_t("Raum")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Raumlänge [m]")}>
									<div>{_t("l")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Raumbreite [m]")}>
									<div>{_t("b")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Gesamtfläche [m²]")}>
									<div>{_t("Fl.gesamt")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Randfläche [m²]")}>
									<div>{_t("Fl.Rand")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Blindfläche [m²]")}>
									<div>{_t("Fl.Blind")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Sollleistung [W]")}>
									<div>{_t("Sollleistung")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("R-Wert Oberfläche [m²K/W]")}>
									<div>{_t("R Oberfl.")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Dämmungsfall")}>
									<div>{_t("Dämmung")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Dämmung Varianten")}>
									<div>{_t("Varianten")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Raumtemperatur [C°]")}>
									<div>{_t("T Raum")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Temperatur des darunterliegenden Raumes [C°]")}>
									<div>{_t("T Angrenz")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Länge der Anbindung (einfache Länge, Abstand vom Verteiler bis zum Raum) [m]")}>
									<div>{_t("Länge Anb.")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title={_t("Anzahl Kreise (Aufenthalt + Rand)")}>
									<div>{_t("Kreise")}</div>
								</Tooltip>
							</TableCell>
							<TableCell align="center"></TableCell>
						</TableRow>
					</TableHead>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppableRooms" direction="vertical">
							{(droppableProvided) => (
								<TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
									{flat.rooms &&
										flat.rooms.map((room, index) => (
											<Draggable key={room._id} draggableId={room.floor + "," + room.flat + "," + room._id} index={index}>
												{(provided, snapshot) => {
													return (
														<TableRow
															ref={provided.innerRef}
															{...provided.draggableProps}
															style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
														>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.count?.text}
																	disableTouchListener={!room?.errors?.count?.text}
																	disableFocusListener={!room?.errors?.count?.text}
																	title={room?.errors?.count?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomCount"
																		value={room.count}
																		inputProps={{ min: 1 }}
																		error={room.errors && room.errors.count && room.errors.count.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomCount");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("count", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<TextField
																	size="small"
																	variant="standard"
																	className="roomName"
																	//to prevent jump cursor to end wheny typing
																	defaultValue={room.name}
																	inputProps={{
																		className: "textArea",
																	}}
																	onClick={selectInput}
																	onChange={(event) => {
																		handleClick_EditRoom("name", event.target.value, floor._id, flat._id, room._id);
																	}}
																	onKeyDown={(e) => {
																		selectNextField(e, ".roomName");
																	}}
																/>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.length?.text}
																	disableTouchListener={!room?.errors?.length?.text}
																	disableFocusListener={!room?.errors?.length?.text}
																	title={room?.errors?.length?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomLength"
																		inputProps={{ min: 0 }}
																		value={room.length}
																		error={room.errors && room.errors.length && room.errors.length.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomLength");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("length", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.width?.text}
																	disableTouchListener={!room?.errors?.width?.text}
																	disableFocusListener={!room?.errors?.width?.text}
																	title={room?.errors?.width?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomWidth"
																		inputProps={{ min: 0 }}
																		value={room.width}
																		error={room.errors && room.errors.width && room.errors.width.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomWidth");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("width", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.total_area?.text}
																	disableTouchListener={!room?.errors?.total_area?.text}
																	disableFocusListener={!room?.errors?.total_area?.text}
																	title={room?.errors?.total_area?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomTotal_area"
																		inputProps={{ min: 0, className: "threeDigits" }}
																		value={room.total_area}
																		error={room.errors && room.errors.total_area && room.errors.total_area.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomTotal_area");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("total_area", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.border_area?.text}
																	disableTouchListener={!room?.errors?.border_area?.text}
																	disableFocusListener={!room?.errors?.border_area?.text}
																	title={room?.errors?.border_area?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomBorder_area"
																		inputProps={{ min: 0, className: "threeDigits" }}
																		value={room.border_area}
																		error={room.errors && room.errors.border_area && room.errors.border_area.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomBorder_area");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("border_area", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.blind_area?.text}
																	disableTouchListener={!room?.errors?.blind_area?.text}
																	disableFocusListener={!room?.errors?.blind_area?.text}
																	title={room?.errors?.blind_area?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomBlind_area"
																		inputProps={{ min: 0, className: "threeDigits" }}
																		value={room.blind_area}
																		error={room.errors && room.errors.blind_area && room.errors.blind_area.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomBlind_area");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("blind_area", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.desired_power?.text}
																	disableTouchListener={!room?.errors?.desired_power?.text}
																	disableFocusListener={!room?.errors?.desired_power?.text}
																	title={
																		room?.errors?.desired_power?.error ? (
																			<div>
																				<Typography component="div" variant="body2">
																					{room?.errors?.desired_power?.text}
																				</Typography>
																				<div>{room?.errors?.desired_power?.title}</div>
																			</div>
																		) : (
																			""
																		)
																	}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomDesired_power"
																		inputProps={{ min: 0, className: "fourDigits", style: { fontWeight: room.user_input ? "bold" : "normal" } }}
																		value={room.desired_power}
																		error={room.errors && room.errors.desired_power && room.errors.desired_power.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomDesired_power");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("desired_power", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<TextField
																	select
																	size="small"
																	variant="standard"
																	className="roomFloor_surface"
																	value={room.floor_surface}
																	onChange={(event) => {
																		handleClick_EditRoom("floor_surface", event.target.value, floor._id, flat._id, room._id);
																	}}
																	// onKeyDown={(e) => {
																	// 	// this.blur();
																	// 	selectNextField(e, ".roomFloor_surface");
																	// }}
																>
																	{FLOOR_SURFACES.map((surface) => (
																		<MenuItem key={surface.key} value={surface.key}>
																			{surface.name}
																		</MenuItem>
																	))}
																</TextField>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<TextField
																	select
																	size="small"
																	variant="standard"
																	className="roomInsulation_case"
																	value={room.insulation_case}
																	onChange={(event) => {
																		handleClick_EditRoom("insulation_case", event.target.value, floor._id, flat._id, room._id);
																		setEditAllInsulationsModal(true);
																		setToEdit({
																			insulation_case: event.target.value,
																			insulation_option_index: 0,
																			floorId: floor._id,
																			flatId: flat._id,
																		});
																	}}
																	// onKeyDown={(e) => {
																	// 	selectNextField(e, ".roomInsulation_case");
																	// }}
																>
																	{DEFAULT_INSULATION_CASES.map((insulation_case) => (
																		<MenuItem key={insulation_case.value} value={insulation_case.value}>
																			{insulation_case.name}
																		</MenuItem>
																	))}
																</TextField>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper} style={{ maxWidth: "100px", minWidth: "100px" }}>
																<TextField
																	select
																	size="small"
																	variant="standard"
																	className="roomInsulation_option_index"
																	value={room.insulation_option_index}
																	error={room.errors && room.errors.insulation_option && room.errors.insulation_option.error}
																	onChange={(event) => {
																		handleClick_EditRoom("insulation_option_index", event.target.value, floor._id, flat._id, room._id);
																		setEditAllVariantsModal(true);
																		setToEdit({
																			insulation_case: room.insulation_case,
																			insulation_option_index: event.target.value,
																			flatId: flat._id,
																			floorId: floor._id,
																		});
																	}}
																	// onKeyDown={(e) => {
																	// 	selectNextField(e, ".roomInsulation_option_index}");
																	// }}
																>
																	{room.insulation_options.map((insulation_option, index) => (
																		<MenuItem key={insulation_option._id} value={index}>
																			{`R=${insulation_option.name}${
																				insulation_option.thickness
																					? `, ${_t("d")}=${Math.round(insulation_option.thickness * 1000)}mm`
																					: ""
																			}`}
																		</MenuItem>
																	))}
																</TextField>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.temperature?.text}
																	disableTouchListener={!room?.errors?.temperature?.text}
																	disableFocusListener={!room?.errors?.temperature?.text}
																	title={room?.errors?.temperature?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		value={room.temperature}
																		className="roomTemperature temperature"
																		//TODO min max temperature?
																		error={room.errors && room.errors.temperature && room.errors.temperature.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomTemperature");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("temperature", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.adjacent_temperature?.text}
																	disableTouchListener={!room?.errors?.adjacent_temperature?.text}
																	disableFocusListener={!room?.errors?.adjacent_temperature?.text}
																	title={room?.errors?.adjacent_temperature?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		value={room.adjacent_temperature}
																		className="roomAdjacent_temperature temperature"
																		error={room.errors && room.errors.adjacent_temperature && room.errors.adjacent_temperature.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomAdjacent_temperature");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("adjacent_temperature", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip
																	disableHoverListener={!room?.errors?.connection_length?.text}
																	disableTouchListener={!room?.errors?.connection_length?.text}
																	disableFocusListener={!room?.errors?.connection_length?.text}
																	title={room?.errors?.connection_length?.text || ""}
																>
																	<TextField
																		type="number"
																		size="small"
																		variant="standard"
																		className="roomConnection_length"
																		inputProps={{ min: 0 }}
																		value={room.connection_length}
																		error={room.errors && room.errors.connection_length && room.errors.connection_length.error}
																		onClick={selectInput}
																		onWheel={(event) => {
																			event.target.blur();
																		}}
																		onKeyDown={(e) => {
																			selectNextField(e, ".roomConnection_length");
																		}}
																		onChange={(event) => {
																			handleClick_EditRoom("connection_length", event.target.value, floor._id, flat._id, room._id);
																		}}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell component="th" scope="row" sx={Wrapper}>
																<Tooltip title={getCircuitTooltip(room)}>
																	<TextField
																		size="small"
																		variant="standard"
																		value={getCircuitText(room)}
																		inputProps={{ className: "threeDigits" }}
																		disabled
																		style={{ backgroundColor: "rgba(0, 0, 0, 0.12)" }}
																	/>
																</Tooltip>
																{room.loading && (
																	<CircularProgress
																		size={24}
																		sx={{
																			// color: green[500],
																			position: "absolute",
																			top: "50%",
																			left: "50%",
																			marginTop: -12,
																			marginLeft: -12,
																		}}
																	/>
																)}
															</TableCell>
															<TableCell component="th" sx={Wrapper} style={{ width: "60px", minWidth: "60px" }}>
																<div style={{ display: "flex", flexDirection: "row" }}>
																	<Tooltip title={_t("Mehr anzeigen")}>
																		<IconButton
																			size="small"
																			aria-label="more"
																			aria-controls="long-menu"
																			aria-haspopup="true"
																			onClick={(event) => {
																				handleClick_toggleMenu(event.currentTarget, floor._id, flat._id, room._id);
																			}}
																		>
																			<MoreVert />
																		</IconButton>
																	</Tooltip>
																	<Menu
																		id="long-menu"
																		anchorEl={room.menu_anchorEl}
																		keepMounted
																		open={Boolean(room.menu_anchorEl)}
																		onClose={(event) => {
																			handleClick_toggleMenu(null, floor._id, flat._id, room._id);
																		}}
																		sx={{ width: "300px" }}
																	>
																		{roomThermostates.length > 0 && (
																			<MenuItem>
																				<TextField
																					select
																					size="small"
																					variant="outlined"
																					label="Thermostat"
																					value={room.thermostate}
																					onChange={(event) => {
																						handleClick_EditRoom("thermostate", event.target.value, floor._id, flat._id, room._id);
																					}}
																				>
																					{roomThermostates.map((roomThermostate) => (
																						<MenuItem key={roomThermostate.key} value={roomThermostate.key}>
																							{roomThermostate.key === "default"
																								? Format(_t("Projekteinstellungen (Wie in Projekt eingestellt %1)"), [
																										roomThermostate.name,
																									])
																								: roomThermostate.name}
																						</MenuItem>
																					))}
																				</TextField>
																			</MenuItem>
																		)}
																		<MenuItem>
																			<TextField
																				fullWidth
																				select
																				size="small"
																				variant="outlined"
																				label={_t("Aufenthaltszone (cm)")}
																				value={room.fixed_main_area_margin}
																				onChange={(event) => {
																					handleClick_EditRoom(
																						"fixed_main_area_margin",
																						event.target.value,
																						floor._id,
																						flat._id,
																						room._id
																					);
																				}}
																			>
																				{roomAreaMargins.map((roomAreaMargin, index) => (
																					<MenuItem key={roomAreaMargin._id} value={index - 1}>
																						{index === 0 ? roomAreaMargin.name : (roomAreaMargin.margin / 10).toFixed(1)}
																					</MenuItem>
																				))}
																			</TextField>
																		</MenuItem>
																		<MenuItem>
																			<TextField
																				fullWidth
																				select
																				size="small"
																				variant="outlined"
																				label={_t("Randzone (cm)")}
																				value={room.fixed_border_area_margin}
																				onChange={(event) => {
																					handleClick_EditRoom(
																						"fixed_border_area_margin",
																						event.target.value,
																						floor._id,
																						flat._id,
																						room._id
																					);
																				}}
																			>
																				{roomAreaMargins.map((roomAreaMargin, index) => (
																					<MenuItem key={roomAreaMargin._id} value={index - 1}>
																						{index === 0 ? roomAreaMargin.name : (roomAreaMargin.margin / 10).toFixed(1)}
																					</MenuItem>
																				))}
																			</TextField>
																		</MenuItem>
																		<Divider />
																		<MenuItem>
																			<Button
																				startIcon={<FileCopy />}
																				color="primary"
																				fullWidth
																				onClick={(e) => {
																					handleClick_DuplicateRoom(floor._id, flat._id, room);
																				}}
																				style={{ justifyContent: "flex-start", paddingLeft: 16 }}
																			>
																				<Typography>{_t("Raum kopieren")}</Typography>
																			</Button>
																		</MenuItem>
																		<MenuItem>
																			<Button
																				startIcon={<Send />}
																				color="primary"
																				fullWidth
																				onClick={(e) => {
																					handleClick_openSendModal("Raum", floor.flats, flat._id, room);
																				}}
																				style={{ justifyContent: "flex-start", paddingLeft: 16 }}
																			>
																				<Typography variant="body1">{_t("Raum senden")}</Typography>
																			</Button>
																		</MenuItem>
																		<MenuItem>
																			<Button
																				startIcon={<Delete />}
																				color="error"
																				fullWidth
																				onClick={(e) => {
																					handleClick_DeleteRoom(floor._id, flat._id, room._id);
																				}}
																				style={{ justifyContent: "flex-start", paddingLeft: 16 }}
																			>
																				<Typography variant="body1">{_t("Raum löschen")}</Typography>
																			</Button>
																		</MenuItem>
																	</Menu>
																	<Tooltip title={_t("Verschieben")} {...provided.dragHandleProps}>
																		<IconButton size="small">
																			<DragIndicator />
																		</IconButton>
																	</Tooltip>
																</div>
															</TableCell>
														</TableRow>
													);
												}}
											</Draggable>
										))}
									{droppableProvided.placeholder}
								</TableBody>
							)}
						</Droppable>
					</DragDropContext>
				</Table>
			</TableContainer>
		</Grid>
	);
}
