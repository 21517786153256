import { Box, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

import Images from "../../store/images";

import logo_linear from "../../images/linear.jpg";

import { getVendors } from "./administrationController";
import { Link } from "react-router-dom";

function PortalView() {
	const [vendors, setVendors] = React.useState([]);

	const handleImageClick = (url) => {
		window.open(url, "_blank");
	};

	React.useEffect(() => {
		async function fetchData() {
			var currentVendors = await getVendors();
			setVendors(currentVendors);
		}
		fetchData();
	}, []);

	return (
		<Grid>
			<Grid container item xs={12} direction="row" style={{ background: "#fafafa", marginTop: "-10px", padding: "15px" }} alignItems="center">
				<Grid item>
					<Box
						component={"img"}
						src={logo_linear}
						sx={{
							objectFit: "contain",
							width: "100px",
							height: "50px",
						}}
						style={{ paddingTop: "0" }}
					></Box>
				</Grid>
				<Grid item>
					<Typography paragraph={true} variant="h5" style={{ paddingLeft: "10px", paddingTop: "8px" }}>
						Fußbodenheizung
					</Typography>
				</Grid>
			</Grid>
			<Grid item container xs={12} style={{ padding: "0px 20px 0px 20px" }}>
				{vendors.map((vendor) => (
					<Grid key={vendor.postfix} item xs={4} sm={3} md={2} xl={1} style={{ cursor: "pointer", background: "#f0f0f0", padding: "2px" }}>
						<Card style={{ background: !vendor.active && "#d7d7d7" }}>
							<CardActionArea
								onClick={() => {
									if (vendor.active) {
										handleImageClick("/landing/" + vendor.secret);
									}
								}}
							>
								<CardContent>
									<Typography style={{ textTransform: "uppercase" }}>{vendor.postfix + " "}</Typography>
									<Typography style={{ textTransform: "uppercase" }}>{vendor.languages.join(", ")}</Typography>
									<Box
										component={"img"}
										src={Images(vendor.postfix).logo ? Images(vendor.postfix).logo : logo_linear}
										sx={{
											objectFit: "contain",
											border: "1px solid",
											width: "100px",
											height: "50px",
										}}
									></Box>
								</CardContent>
							</CardActionArea>
						</Card>
						{vendor.url && (
							<Link target="_blank" rel="noopener noreferrer" to={"//" + vendor.url} style={{ wordWrap: "break-word", width: "100%" }}>
								{vendor.url}
							</Link>
						)}
						{vendor.urls &&
							vendor.urls.map((vendorUrl, index) => (
								<Grid container key={vendorUrl}>
									{index + 1 + ":"}
									<Link target="_blank" rel="noopener noreferrer" to={"//" + vendorUrl} style={{ wordWrap: "break-word", width: "100%" }}>
										{vendorUrl}
									</Link>
								</Grid>
							))}
						{!vendor.url && vendor.urls.length === 0 && <Typography color="error">no subdomain</Typography>}
					</Grid>
				))}
			</Grid>
		</Grid>
	);
}
export default PortalView;
