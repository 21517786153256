import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Grid, CssBaseline } from "@mui/material";

import Footer from "./footer/footer.js";
import Header from "./header/header.js";
import { Copyright } from "./copyright/copyright.js";
import { VendorStylesContainer } from "../mainStyle.js";

import { ModalDialog } from "../components/shared/modals/modalDialog.js";

function App(props) {
	React.useEffect(() => {
		async function fetchData() {
			if (props?.vendor?.showModal) {
				props.closed();
			}
		}
		fetchData();
	}, []);

	return (
		<Fragment>
			<VendorStylesContainer
				sx={{
					fontFamily:
						'-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol","Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",		"Droid Sans", "Helvetica Neue", "sans-serif"',
					fontSize: "14px",
					lineHeight: "1.5",
					color: "#24292e",
					backgroundColor: "#fff",
					WebkitFontSmoothing: "antialiased",
					MozOsxFontSmoothing: "grayscale",
					margin: "0",
				}}
				vendor={props.vendor.postfix}
			>
				<Header />
				<CssBaseline />
				<Grid
					component="main"
					role="main"
					sx={{ padding: "79px 20px 0px 20px", minHeight: "calc(100vh - 40px)", backgroundColor: "#fefefe" }}
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					item
					xs={12}
				>
					<Grid container item sx={{ flexGrow: "1" }} component={VendorStylesContainer} vendor={props.vendor.postfix}>
						{props?.vendor?.showModal && (
							<ModalDialog
								vendor={props.vendor}
								opened={props?.vendor?.showModal}
								closed={props.closed}
								title={props.vendor.modalTitle}
								text={props.vendor.modalText}
							/>
						)}
						{props.children}
					</Grid>
					<Grid item container justifyContent="flex-end">
						<Copyright />
					</Grid>
				</Grid>
				<Footer />
			</VendorStylesContainer>
		</Fragment>
	);
}
const mapStateToProps = (state) => {
	return {
		vendor: state.vendor,
		user: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closed: () => dispatch({ type: "error_modal", payload: { show: false, title: "", text: "" } }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
