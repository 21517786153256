import axios from "axios";
import { VENDOR_SERVER } from "../../config.js";
import { UPLOAD_JSONDATA } from "../../store/types.js";

export function changeVendorLanguage(lang) {
	const request = axios({
		method: "get",
		url: `${VENDOR_SERVER}/language/${lang}`,
		baseURL: "/",
	})
		.then((response) => {
			return response?.data?.json || {};
		})
		.catch((err) => {
			return err?.response;
		});
	return {
		type: UPLOAD_JSONDATA,
		payload: request,
	};
}
