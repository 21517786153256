import axios from "axios";
import { JSON_SERVER, VENDOR_SERVER } from "../../config";
import { sortObjByItem } from "../../store/gloablFunctions";
import { UPLOAD_JSONDATA } from "../../store/types";

export function uploadJSONFile(formData, isCurrentVendor) {
	const request = axios({
		method: "post",
		url: `${JSON_SERVER}/upload`,
		baseURL: "/",
		data: formData,
		headers: { "Content-Type": "multipart/form-data" },
	})
		.then((response) => {
			return response?.data;
		})
		.catch((err) => {
			return err;
		});

	return {
		type: isCurrentVendor ? UPLOAD_JSONDATA : "no_update",
		payload: request,
	};
}

export async function getVendors() {
	try {
		var response = await axios({
			method: "get",
			url: `${VENDOR_SERVER}/fetch/`,
			baseURL: "/",
		});
		var vendors = sortObjByItem(response?.data, "name", true, true);
		return vendors;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function createVendor(dataToSubmit) {
	try {
		var response = await axios({
			method: "post",
			url: `${VENDOR_SERVER}/add`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		return error.response.status;
	}
}

export async function upsertVendors(dataToSubmit) {
	try {
		var response = await axios({
			method: "post",
			url: `${VENDOR_SERVER}/upsert`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		return error.response.status;
	}
}

export async function editVendor(vendorId, dataToSubmit) {
	try {
		var response = await axios({
			method: "put",
			url: `${VENDOR_SERVER}/edit/${vendorId}`,
			baseURL: "/",
			data: { data: dataToSubmit },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}

export async function getJsonsByVendor(vendorId, languages) {
	try {
		var response = await axios({
			method: "get",
			url: `${JSON_SERVER}/getJsonsByVendor`,
			baseURL: "/",
			params: { vendorId, languages },
		});
		return response?.data;
	} catch (error) {
		console.error(error);
		alert(error?.userMessage);
	}
}
