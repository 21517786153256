import axios from "axios";
import { logoutUser, vendorAuth } from "./auth";
import { _t } from "./translate";

export function errorInterceptor(store) {
	const { dispatch } = store;
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (401 === error.response.status) {
				const state = store.getState();
				if (state.vendor && state.vendor.secret) {
					return dispatch(vendorAuth(state.vendor.secret)).then((response) => {
						if (state.user.user) {
							return dispatch(logoutUser()).then((response) => {
								if (response.payload === "logged out!") {
									window.location = "/";
								} else {
									console.log("no data");
								}
							});
						}
					});
				}
			} else {
				if (error.response) {
					// var status = error.response.status;
					// var statusText = error.response.statusText;
					var message = error.response?.data ? error.response?.data.userMessage : "";
					const state = store.getState();
					if (!state.vendor.showModal && state.vendor.modalText !== message) {
						dispatch({ type: "error_modal", payload: { show: true, title: _t("Fehler"), text: message } });
					}
					return null;
				}
				return Promise.reject(error);
			}
		}
	);
}
