import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Link, Paper, Typography } from "@mui/material";

import { GridImageContainer, GridPaperContainer } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { verifyUser } from "./verifyController.js";
import { Format, _t } from "../../store/translate.js";

function Verify(props) {
	const navigate = useNavigate();
	const { email, id } = useParams();

	const handleClick_verifyUser = async () => {
		var request = await verifyUser(email, id);
		if (request) {
			navigate("/", {
				state: { justVerified: true },
			});
		}
	};

	React.useEffect(() => {
		handleClick_verifyUser();
	}, []);

	return (
		<Layout>
			<Grid container>
				<Grid item xs={false} sm={4} md={7} component={GridImageContainer} vendor={props.vendor.postfix} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<GridPaperContainer>
						<Typography component="h1" variant="h5" paragraph>
							<Link href="#" onClick={handleClick_verifyUser}>
								{Format(_t("%1 bestätigen"), [email])}
							</Link>
						</Typography>
					</GridPaperContainer>
				</Grid>
			</Grid>
		</Layout>
	);
}

export default Verify;
