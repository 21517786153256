import Cookies from "js-cookie";
import de from "../i18n/dict.DE";
import en from "../i18n/dict.EN";
import fr from "../i18n/dict.FR";
import it from "../i18n/dict.IT";
import nl from "../i18n/dict.NL";
import ru from "../i18n/dict.RU";
import { DICTIONARY } from "./types";
import store from "./store.js";

async function readTextFile(file) {
	return new Promise(function (resolve, reject) {
		var xhr = new XMLHttpRequest();
		xhr.open("GET", file, true);
		xhr.onload = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200 || xhr.status === 0) {
					var allText = xhr.responseText;
					var parsed = parse(allText);
					resolve(parsed);
				}
			}
		};
		xhr.send(null);
	});
}

function parse(wordbook) {
	var lines = wordbook.split("\r\n");
	if (lines.length === 1) {
		lines = wordbook.split("\n");
	}
	var dict = {};
	lines.forEach((line) => {
		if (line.length) {
			var parts = line.split(" <*> ");
			dict[parts[0]] = parts[1];
		}
	});
	return dict;
}

export async function setDictionary() {
	var dictionaries = { de, en, fr, it, nl, ru };
	var currentLanguage = Cookies.get("language");
	if (currentLanguage && currentLanguage.split("-").length === 2) {
		currentLanguage = currentLanguage.split("-")[1];
	}
	var dict = dictionaries[currentLanguage];
	var text = readTextFile(dict);

	return {
		type: DICTIONARY,
		payload: text,
	};
}

export function _t(text) {
	var state = store.getState();
	var langTable = state.vendor.dictionary;
	var tr = null;
	if (langTable !== undefined) tr = langTable[text];
	var out;
	if (tr) {
		out = tr;
	} else {
		var currentLanguage = Cookies.get("language");
		if (currentLanguage && currentLanguage.split("-").length === 2) {
			currentLanguage = currentLanguage.split("-")[1];
		}
		if (currentLanguage !== "de") {
			out = "[" + text + "]";
		} else {
			out = text;
		}
	}
	//beware comments for emails
	out = out.replace(/@.+@/g, "");

	return out;
}

export function Format(text, args) {
	for (let index = 0; index < args.length; index++) {
		var placeholder = "%" + (index + 1);
		text = text.replaceAll(placeholder, args[index]);
	}
	return text;
}

export function FormatHTML(text, html) {
	var textArray = text.split("%1");
	return (
		<div>
			{textArray[0]} {html} {textArray[1]}
		</div>
	);
}
