import { LOGIN_USER, LOGOUT_USER, UPLOAD_JSONDATA } from "../types";

const initState = {};

export default function user(state = initState, action) {
	if (action && action.payload && !action.payload.isAxiosError) {
		switch (action.type) {
			case LOGIN_USER: {
				//migrated users will not be logged in for the firsttime but will be redirected to reset password.
				//when there is no user in payload means there is a user message to check email for password reset
				if (action.payload._id) {
					return {
						...state,
						user: {
							email: action.payload.email,
							firstname: action.payload.firstname,
							lastname: action.payload.lastname,
							role: action.payload.role,
							default_address: action.payload.default_address,
							// vendor: action.payload.vendor,
							// verified: action.payload.verified,
							_id: action.payload._id,
						},
					};
				} else {
					return state;
				}
			}
			case LOGOUT_USER:
				return { ...state, user: null, jsonDataId: null };
			case UPLOAD_JSONDATA:
				return {
					...state,
					jsonDataId: action.payload?._id,
				};
			default:
				return state;
		}
	} else {
		return state;
	}
}
